import {
  getPaymentTransactionsAPI,
  getSalesChartAPI,
} from "apis/SalesReportAPI";
import { Helper } from "core/helper";
import { showToastDanger } from "core/toast.service";
import { PaymentTransactions } from "models/SalesReport/payment-transactions.model";
import { SalesChart } from "models/SalesReport/sales-chart-model";
import React, { createContext, useEffect, useState } from "react";

interface SalesReportContextData {
  sales: SalesChart[];
  paymentTransactions: PaymentTransactions[];
  loading: boolean;
  loadingPaymentTransactions: boolean;
  totalRows: number;
  currentPage: number;
  selectedRow: any;
  fromDate: string;
  toDate: string;

  fetchSalesReport: (fromDate: string, toDate: string) => void;
  fetchPaymentTransactions: (
    fromDate: string,
    toDate: string,
    currentPage: number
  ) => void;
  onPaginate: (page: number) => void;
  onSelectRow: (item: any) => void;
  onChangeFromDate: (item: any) => void;
  onChangeToDate: (item: any) => void;
  onFilterSalesReport: () => void;
}

interface SalesReportProviderProps {
  children: React.ReactNode;
}

export const CHART_OPTIONS = {
  theme: "light2",
  animationEnabled: true,
  exportEnabled: true,
  exportFileName: `Mambusao Sales Report`,
  zoomEnabled: true,
  title: {
    text: `Sales Report for 02-01-2022`,
    fontSize: 18,
  },
  subtitles: [
    {
      text: "Mambusao Sales Report",
      margin: 30,
    },
  ],
  axisX: {
    title: "Time",
    titleFontSize: 15,
  },
  axisY: {
    title: "Sales",
    titleFontSize: 15,
  },
  toolTip: {
    shared: true,
    fontSize: 14,
    borderColor: "#444",
    fontColor: "#444",
    content: `{label} <br /> <b> Sale this day: ₱{ y } </b>`,
  },
  dataPointWidth: 25,
};

const year = new Date().getFullYear();
const month = new Date().getMonth();
const dtoday = new Date().getDate();

export const SalesReportContext = createContext<SalesReportContextData>({
  sales: [],
  paymentTransactions: [],
  loading: true,
  loadingPaymentTransactions: true,
  totalRows: 0,
  currentPage: 1,
  fromDate: "",
  toDate: "",
  selectedRow: undefined,
  fetchSalesReport: () => {},
  fetchPaymentTransactions: () => {},
  onPaginate: () => {},
  onSelectRow: () => {},
  onChangeFromDate: () => {},
  onChangeToDate: () => {},
  onFilterSalesReport: () => {},
});

export const SalesReportProvider: React.FC<SalesReportProviderProps> = ({
  children,
}) => {
  const [sales, setSales] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [paymentTransactions, setPaymentTransactions] = useState<any[]>([]);
  const [loadingPaymentTransactions, setLoadingPaymentTransactions] =
    useState(true);

  const [fromDate, setFromDate] = useState(
    Helper.ISOToMMDDYYYY(new Date().toISOString())
  );
  const [toDate, setToDate] = useState(
    Helper.ISOToMMDDYYYY(new Date().toISOString())
  );

  useEffect(() => {
    fetchSalesReport(fromDate, toDate);
  }, []);

  useEffect(() => {
    fetchPaymentTransactions();
  }, [currentPage]);

  const fetchSalesReport = async (
    fromDate: string,
    toDate: string
  ): Promise<void> => {
    setLoading(true);
    CHART_OPTIONS.title.text = `Sales Report for ${fromDate} - ${toDate}`;
    try {
      const result = await getSalesChartAPI(fromDate, toDate);
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data");
    }
  };

  const fetchPaymentTransactions = async (): Promise<void> => {
    setLoadingPaymentTransactions(true);
    try {
      const result = await getPaymentTransactionsAPI(
        fromDate,
        toDate,
        currentPage
      );
      setPaymentTransactions(result.data);
      setLoadingPaymentTransactions(false);

      const page = result.page;
      if (page) {
        setTotalRows(page.total);
      }
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data");
    }
  };

  const onFilterSalesReport = (): void => {
    fetchSalesReport(fromDate, toDate);
    fetchPaymentTransactions();
  };

  const onPaginate = (page: number): void => {
    setCurrentPage(page);
  };

  const onSelectRow = (item: any): void => {
    setSelectedRow(item);
  };

  const onChangeFromDate = (event: any): void => {
    setFromDate(event.target.value);
  };

  const onChangeToDate = (event: any): void => {
    setToDate(event.target.value);
  };

  const renderResult = (res: any): void => {
    let result = res.data;
    result = result.map((d: any) => ({
      label: new Date(year, month, dtoday, d.label, 0).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      x: new Date(year, month, dtoday, d.label, 0),
      y: d.y,
    }));

    if (result) {
      setSales(result);
      setLoading(false);
    }
  };

  return (
    <SalesReportContext.Provider
      value={{
        sales,
        paymentTransactions,
        loading,
        loadingPaymentTransactions,
        totalRows,
        currentPage,
        selectedRow,
        fromDate,
        toDate,

        fetchSalesReport,
        fetchPaymentTransactions,
        onPaginate,
        onSelectRow,
        onChangeFromDate,
        onChangeToDate,
        onFilterSalesReport,
      }}
    >
      {children}
    </SalesReportContext.Provider>
  );
};
