
import { GlobalVar } from "apis/core/GlobalVar";
import axios from "apis/core/Axios";

export const createServiceAPI = async (data: any) => {
    const response = await axios.post("/service", data, { headers: GlobalVar.Http });
    return response.data;
}

export const updateServiceAPI = async (id: string, data: any) => {
    const response = await axios.post(`/service`, data, { headers: GlobalVar.Http });
    return response.data;
}

export const getServiceListAPI = async () => {
    // const response = await axios.post("/service", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get("https://api.npoint.io/8bc8e75bf58574f77c3b");
    return response.data;
}

export const deleteServiceAPI = async (service_id: string) => {
    const response = await axios.delete(`/service/${service_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getServiceLogsAPI = async (page: number) => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get(`https://api.npoint.io/3dd3da17ab5551ed88e6?page=${page}`);
    return response.data;
}