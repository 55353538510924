import React, { useContext, useEffect, useState } from 'react';
import 'react-notifications-component/dist/theme.css';
import { useNavigate } from 'react-router-dom';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { loginUser } from '../../apis/LoginAPI';
import { AuthContext } from '../../contexts/AuthContext.js';
import { showToastDanger } from '../../core/toast.service';
import { PageSettings } from '../../config/page-settings.js';
import {
    printTicketAPI,
} from "apis/Inventory/TicketAPI";

function Login() {
    const navigate = useNavigate();
    const { handleSetPageSidebar, handleSetPageHeader, handleSetBodyWhiteBg, handleSetPageTopMenu, handleSetPageFooter } = useContext(PageSettings);
    const { setToken } = useContext(AuthContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        handleSetPageSidebar(false);
        handleSetPageHeader(false);
        handleSetBodyWhiteBg(true);
        handleSetPageTopMenu(false);
        handleSetPageFooter(false);

        return () => {
            handleSetPageSidebar(true);
            handleSetPageHeader(true);
            handleSetPageTopMenu(false);
            handleSetBodyWhiteBg(false);
            handleSetPageFooter(false);
        }
    }, [])

    const handleSubmit = (e) => {

        e.preventDefault();
        setFormSubmitted(true);

        if (!isValidForm()) {
            return;
        }

        setLoadingSubmit(true);

        loginUser({ username, password }).then(res => {
            const token = res.data.token;

            const user = res.data.user;
            localStorage.setItem('role', user.role);

            setToken(token);
            navigate("/events-and-orders", { replace: true });

        }, (err) => {
            showToastDanger('Error', 'Invalid account. Please try again.');
            setLoadingSubmit(false);
        })
    }

    const isValidForm = () => {
        if (username.trim().length === 0 || password.trim().length === 0) {
            return false;
        }
        return true;
    }


    return (
        <div className="login login-with-news-feed">
            <div className="news-feed">
                <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/tapi.jpg)' }}></div>
                {/* <div className="news-caption">
                    <h4 className="caption-title"><b>Color</b> Admin App</h4>
                    <p>
                        Download the Color Admin app for iPhone®, iPad®, and Android™. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                </div> */}
            </div>
            <div className="right-content">
                <div className="login-header">
                    <div className='d-flex justify-content-center'>
                        <img src="assets/img/logo/logo.jpg" width={100} height={100} alt="Mambusao Logo" />
                    </div>
                    <div className="brand">
                        {/* <span className="logo"></span>  */}
                        <b className='text-success'>Mambusao Spring Resort</b>
                        <small>Sign in to your account to continue.</small>
                    </div>
                    <div className="icon">
                        <i className="fa fa-sign-in"></i>
                    </div>
                </div>
                <div className="login-content">
                    <Form className="margin-bottom-0" onSubmit={handleSubmit}>

                        <FormGroup className="form-group mb-4">
                            <Label for="username" className='font-weight-bold'>
                                Username
                            </Label>
                            <Input
                                autoComplete='off'
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                type="text"
                                className="form-control form-control-lg"
                                invalid={formSubmitted && !username} />
                            <FormFeedback>
                                Username is required.
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup className="form-group">
                            <Label for="password" className='font-weight-bold'>
                                Password
                            </Label>
                            <Input
                                autoComplete='off'
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={togglePassword ? 'text' : 'password'}
                                className="form-control form-control-lg"
                                invalid={formSubmitted && !password} />
                            <FormFeedback>
                                Password is required.
                            </FormFeedback>
                        </FormGroup>

                        <div className="checkbox checkbox-css mb-5">
                            <input checked={togglePassword} onChange={(e) => setTogglePassword(e.target.checked)} type="checkbox" id="remember_me_checkbox" value="" />
                            <label className='form-check-label fs-13px text-gray-500' htmlFor="remember_me_checkbox">
                                Show Password
                            </label>
                        </div>

                        <div className="login-buttons">
                            <button disabled={loadingSubmit} type="submit" className="btn btn-success btn-block btn-lg">
                                {loadingSubmit ? 'SIGNING IN' : 'SIGN IN'}</button>
                        </div>

                        {/* <hr />
                        <p className="text-center text-grey-darker">
                            &copy; All Right Reserved 2023
                        </p> */}
                    </Form>
                </div>
            </div >
        </div >
    )
}

export default Login