import { v4 as uuidv4 } from "uuid";

export class Helper {


    static ISOToMMDDYYYY(date: string): string {
        return date.replace(/T.*$/, '');
    }

    // this returns 2023-03-10 YYYY-MM-DD
    static getCurrentDate(): string {
        const date = Helper.ISOToMMDDYYYY(new Date().toISOString())
        return date;
    }

    static YYYYMMDDToMMDDYYYY(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${month}-${day}-${year}`;
    }

    static getFormattedDateTime(date: Date) {
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const year = date.getFullYear();
        const hour = ("0" + ((date.getHours() + 11) % 12 + 1)).slice(-2);
        const minute = ("0" + date.getMinutes()).slice(-2);
        const second = ("0" + date.getSeconds()).slice(-2);
        const period = (date.getHours() >= 12) ? "PM" : "AM";
        return `${month}-${day}-${year}, ${hour}:${minute}:${second} ${period}`;
    }

    static appendEightHoursToDate(date: Date): Date {
        let result = new Date(date);
        result.setHours(8, 0, 0, 0);

        return result;
    }

    static calculateDiscount(originalTotal: number, currentTotal: number): number {
        const difference = originalTotal - Number(currentTotal);
        if (Number(currentTotal) === 0) {
            return 100;
        }

        if (originalTotal <= 0 || Number(currentTotal) <= 0 || difference <= 0) {
            return 0;
        }

        const result = (Number(currentTotal) == 0) ? 0 : 100 * (difference / originalTotal);
        return Helper.formatToTwoDecimal(result);
    }

    static validateDecimalInput = (value: string): boolean => {
        const pattern = /^\d*(\.\d{0,2})?$/;
        return pattern.test(value);
    };

    static formatToTwoDecimal(value: number): number {
        return parseFloat(value.toFixed(2));
    }

    static numToFixed(value: number): string {
        return String(value.toFixed(2))
    }

    static formatTransactionNo(value: number): string {
        return String(value).padStart(4, '0');
    }

    static generateUUID(): string {
        return uuidv4();
    }

    static generateOrderNumber(): string {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let orderNumber = "";
        for (let i = 0; i < 2; i++) {
            orderNumber += letters.charAt(Math.floor(Math.random() * letters.length));
        }
        for (let i = 0; i < 4; i++) {
            orderNumber += Math.floor(Math.random() * 10);
        }
        return orderNumber;
    }


    static getCurrentUserID = (): string | null => {
        const user_id = localStorage.getItem("user_id");
        return user_id
    }

    static getCurrentTime = (): string => {
        const now = new Date();

        // get the local time string with seconds
        const time = new Date().toTimeString().split(" ")[0]

        return time;
    }



}