import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AuthContext } from '../../../contexts/AuthContext';

function DropdownProfile() {
	const navigate = useNavigate();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const { setToken } = useContext(AuthContext);

	const toggle = () => {
		setDropdownOpen(status => !status);
	}

	const logout = () => {
		setToken(null);
		navigate("/login");
		// localStorage.clear();
	}


	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user cursor-pointer" tag="li">
			<DropdownToggle tag="a">
				<div className="image image-icon bg-teal text-white">
					<i className="fa fa-user"></i>
				</div>
				{/* <span className="d-none d-md-inline"></span> <b className="caret"></b> */}
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu" tag="ul">
				{/* <DropdownItem>Edit Profile</DropdownItem> */}
				{/* <DropdownItem><span className="badge badge-danger pull-right">0</span> Inbox</DropdownItem> */}
				{/* <DropdownItem>Calendar</DropdownItem> */}
				{/* <DropdownItem>Setting</DropdownItem> */}
				{/* <div className="dropdown-divider"></div> */}
				<DropdownItem onClick={logout}>Log Out</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default DropdownProfile;
