import React, { RefObject, useContext, useRef, useState } from "react";
import FullCalendar, {
  CalendarApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import { formatDate } from "@fullcalendar/core";
import CalendarNoteForm from "./CalendarNoteForm";
import { CalendarContext } from "contexts/CalendarContext";
import { Helper } from "core/helper";
import { updateCalendarEventAPI } from "apis/CalendarAPI";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { CalendarNote } from "models/Calendar/calendar-note.model";

const Calendar = () => {
  const {
    handleDatesSet,
    calendarRef,
    calendarEvents,
    loading,
    rerenderCalendar,
    onSelectNote,
    onSelectDate,
  } = useContext(CalendarContext);

  const [showNoteForm, setShowNoteForm] = useState(false);

  const handleDateClick = (arg: DateClickArg) => {
    onSelectNote(undefined);
    onSelectDate(arg.dateStr);
    setShowNoteForm(true);
  };

  const handleEventClick = (arg: EventClickArg) => {
    onSelectNote({
      ...arg.event.extendedProps,
      id: arg.event.id,
      color: arg.event.backgroundColor,
    });
    setShowNoteForm(true);
  };

  const handleEventResize = (args: EventResizeDoneArg) => {
    handleCalendarInteractions(args);
  };

  const handleEventDrop = (args: EventDropArg): void => {
    handleCalendarInteractions(args);
  };

  const handleCalendarInteractions = async (
    args: EventResizeDoneArg | EventDropArg
  ): Promise<void> => {
    const start = args.event.start;
    const end = args.event.end;

    if (start && end) {
      const time_start = start.toLocaleTimeString("en-US", { hour12: false });
      const time_end = end.toLocaleTimeString("en-US", { hour12: false });
      const color = args.event.backgroundColor;

      try {
        const note = args.event.extendedProps as CalendarNote;
        const note_id = args.event.id;
        await updateCalendarEventAPI(note_id, {
          ...note,
          date_start: Helper.ISOToMMDDYYYY(args.event.startStr),
          date_end: Helper.ISOToMMDDYYYY(args.event.endStr),
          time_start,
          time_end,
          color,
        });

        showToastSuccess("Success", "Note updated successfully");
        rerenderCalendar();
      } catch (e) {
        args.revert();
        showToastDanger("Error", "There was a problem updating the note");
      }
    }
  };

  const closeNoteForm = (status: boolean): void => {
    setShowNoteForm(status);
  };

  const handleEventContent = (arg: EventContentArg) => {
    const { event, view } = arg;
    const myTitle = event.extendedProps?.note;
    const bg = event.backgroundColor;
    return (
      <div
        className="fc-content text-truncate w-100"
        style={{ backgroundColor: bg }}
      >
        {myTitle}
      </div>
    );
  };

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={calendarEvents}
        headerToolbar={{
          // start: `dayGridMonth`,
          start: "title",
          end: "today,prev,next",
        }}
        allDaySlot={false}
        editable={true}
        eventResizableFromStart={true}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        eventResize={handleEventResize}
        eventDrop={handleEventDrop}
        eventContent={handleEventContent}
        datesSet={handleDatesSet}
        slotMinTime={"08:30"}
        slotMaxTime={"22:00"}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        }}
        showNonCurrentDates={false}
      />

      {showNoteForm && <CalendarNoteForm closeNoteForm={closeNoteForm} />}
    </>
  );
};

export default Calendar;
