import { getActivityLogsAPI } from "apis/ActivityLogsAPI";
import { Helper } from "core/helper";
import { showToastDanger } from "core/toast.service";
import { ActivityLogs } from "models/ActivityLogs/activity-logs-model";
import React, { createContext, useEffect, useState } from "react";

interface ActivityLogsContextData {
  activityLogs: ActivityLogs[];
  loading: boolean;
  totalRows: number;
  currentPage: number;
  fromDate: string;

  fetchActivityLogs: () => void;
  onPaginate: (page: number) => void;
  onChangeFromDate: (event: any) => void;
}

interface ActivityLogsProviderProps {
  children: React.ReactNode;
}

export const ActivityLogsContext = createContext<ActivityLogsContextData>({
  activityLogs: [],
  loading: true,
  totalRows: 0,
  fromDate: Helper.ISOToMMDDYYYY(new Date().toISOString()),
  currentPage: 1,

  fetchActivityLogs: () => {},
  onPaginate: () => {},
  onChangeFromDate: () => {},
});

export const ActivityLogsProvider: React.FC<ActivityLogsProviderProps> = ({
  children,
}) => {
  const [activityLogs, setActivityLogs] = useState<ActivityLogs[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [fromDate, setFromDate] = useState(
    Helper.ISOToMMDDYYYY(new Date().toISOString())
  );

  useEffect(() => {
    fetchActivityLogs();
  }, [currentPage]);

  const fetchActivityLogs = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await getActivityLogsAPI(currentPage);
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data.");
    }
  };

  const onPaginate = (page: number): void => {
    setCurrentPage(page);
  };

  const onChangeFromDate = (event: any): void => {
    setFromDate(event.target.value);
    fetchActivityLogs();
  };

  const renderResult = (result: any): void => {
    const data = result.data;
    const page = result.page || result.pages;

    if (data) {
      setActivityLogs(data);
      setLoading(false);
    }

    if (page) {
      setTotalRows(page.total);
    }
  };

  return (
    <ActivityLogsContext.Provider
      value={{
        activityLogs,
        loading,
        totalRows,
        currentPage,
        fromDate,

        fetchActivityLogs,
        onPaginate,
        onChangeFromDate,
      }}
    >
      {children}
    </ActivityLogsContext.Provider>
  );
};
