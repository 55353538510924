import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { environment } from "environments/environment.prod";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: environment.api,
});

axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        // Add any request headers or modify request data here
        return config;
    },
    (error: any) => {
        // Handle request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // console.warn('response', response)
        if (response.status >= 400 && response.status < 500) {
            window.location.replace("/login");
        }
        // Handle response data here
        return response;
    },
    (error: any) => {
        // Handle response error
        return Promise.reject(error);
    }
);

export default axiosInstance;
