import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface Props {
  showDialog: boolean;
  closeDialog: any;
  title: string;
  message: string;
}

const ReusableModal: React.FC<Props> = (props) => {
  const { showDialog, closeDialog, title, message } = props;

  return (
    <div>
      <Modal isOpen={showDialog} toggle={() => closeDialog(false)}>
        <ModalHeader toggle={() => closeDialog(false)}>{title}</ModalHeader>
        <ModalBody className="mb-5">{message}</ModalBody>
        <ModalFooter>
          <Button size="lg" color="white" onClick={() => closeDialog(false)}>
            Cancel
          </Button>
          <Button size="lg" color="success" onClick={() => closeDialog(true)}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReusableModal;
