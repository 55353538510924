import React, { useEffect, useState } from "react";
import Select from "react-select";

interface Props {
  onChangeAssigned: (event: any) => void;
  selectedOption: string;
}

const roles = [
  { value: "secretary", label: "Secretary" },
  { value: "guard", label: "Guard" },
  { value: "admin", label: "Admin" },
];

const SelectAssignedRole: React.FC<Props> = ({
  onChangeAssigned,
  selectedOption,
}: Props) => {
  const [options, setOptions] = useState<any>(roles);
  const [defaultOption, setDefaultOption] = useState({});

  useEffect(() => {
    if (selectedOption) {
      const result = roles.find(
        (option: any) => option.value === selectedOption
      );

      if (result) {
        setDefaultOption(result);
      }
    }
  }, [selectedOption]);

  return (
    <Select
      value={defaultOption}
      isClearable={false}
      isSearchable={false}
      onChange={onChangeAssigned}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={options}
    />
  );
};

export default SelectAssignedRole;
