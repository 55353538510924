import { getAllTickets } from "apis/Inventory/InventoryAPI";
import React, { useEffect, useState } from "react";
import Select from "react-select";

interface Props {
  onChangeTicket: (event: any) => void;
}

const SelectTicket: React.FC<Props> = ({ onChangeTicket }) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    const getData = async () => {
      const result = await getAllTickets();
      setOptions(result.data);
    };

    getData();
  }, []);

  return (
    <>
      <Select
        onChange={onChangeTicket}
        isClearable={false}
        isSearchable={false}
        options={options}
        getOptionLabel={(option: any) =>
          String(`${option.name} ${option.specification}`).toLocaleUpperCase()
        }
        getOptionValue={(option: any) =>
          String(option.description).toLocaleUpperCase()
        }
      />
    </>
  );
};

export default SelectTicket;
