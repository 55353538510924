import { APP_COLORS } from "core/contants";
import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

interface Props {
  onChangeColor: (color: string) => void;
  selectedOption: string;
}

const SelectColorAssigned: React.FC<Props> = ({
  onChangeColor,
  selectedOption,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const onClickColor = (color: string) => {
    toggle();
    onChangeColor(color);
  };

  return (
    <>
      <input
        style={{ backgroundColor: selectedOption, color: "#FFFFFF" }}
        // value={selectedOption}
        readOnly
        type="text"
        id="Popover1"
        className="form-control cursor-pointer"
        onClick={toggle}
      />
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target="Popover1"
        toggle={toggle}
      >
        <PopoverHeader>Select Colors</PopoverHeader>
        <PopoverBody>
          <div className="row">
            {APP_COLORS.map((color, index) => {
              return (
                <div
                  onClick={() => onClickColor(color)}
                  key={index}
                  className="col-md-2 mb-2 text-center"
                >
                  <a
                    href="#"
                    className="btn btn-gray btn-icon btn-circle"
                    style={{ backgroundColor: color }}
                  >
                    <i className="fa fa-check fa-xs invisible"></i>
                  </a>
                </div>
              );
            })}
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default SelectColorAssigned;
