import {
  getAllTickets,
  getProductListAPI,
  getRentalListAPI,
  getServiceListAPI,
} from "apis/Inventory/InventoryAPI";
import { INVENTORY_TYPE } from "core/contants";
import { showToastDanger } from "core/toast.service";
import { InventoryTableModel } from "models/Inventory/inventory-table.model";
import React, { createContext, useContext, useEffect, useState } from "react";

// Define the shape of your context data
interface InventoryContextData {
  inventory: InventoryTableModel[];
  loading: boolean;
  totalRows: number;
  currentPage: number;
  selectedTab: INVENTORY_TYPE;
  selectedRow: any;
  searchTerm: string;

  fetchServiceList: () => void;
  fetchTicketList: () => void;
  fetchRentalList: () => void;
  fetchProductList: () => void;
  onPaginate: (page: number) => void;
  onChangeTab: (tab: INVENTORY_TYPE) => void;
  onSelectRow: (item: any) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// Create a new context with the default value
export const InventoryContext = createContext<InventoryContextData>({
  inventory: [],
  loading: true,
  totalRows: 0,
  currentPage: 1,
  selectedTab: INVENTORY_TYPE.TICKET,
  selectedRow: undefined,
  searchTerm: "",

  fetchServiceList: () => {},
  fetchTicketList: () => {},
  fetchRentalList: () => {},
  fetchProductList: () => {},
  onPaginate: () => {},
  onChangeTab: () => {},
  onSelectRow: () => {},
  handleSearchChange: () => {},
});

// Define a provider component that will wrap your app
interface InventoryProviderProps {
  children: React.ReactNode;
}

export const InventoryProvider: React.FC<InventoryProviderProps> = ({
  children,
}) => {
  const [inventory, setInventory] = useState<InventoryTableModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedTab, setSelectedTab] = useState(INVENTORY_TYPE.TICKET);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchInventoryData = (): void => {
    switch (selectedTab) {
      case INVENTORY_TYPE.PRODUCT:
        fetchProductList();
        break;

      case INVENTORY_TYPE.SERVICE:
        fetchServiceList();
        break;

      case INVENTORY_TYPE.RENTAL:
        fetchRentalList();
        break;

      case INVENTORY_TYPE.TICKET:
        fetchTicketList();
        break;

      default:
        fetchProductList();
    }
  };

  useEffect(() => {
    fetchInventoryData();
  }, [currentPage, selectedTab]);

  useEffect(() => {
    if (searchTerm) {
      const debounceTimeout = setTimeout(() => {
        console.log(`Searching for: ${searchTerm}`);
        fetchInventoryData();
      }, 400);

      return () => clearTimeout(debounceTimeout);
    } else {
      fetchInventoryData();
    }
  }, [searchTerm]);

  const fetchServiceList = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await getServiceListAPI(currentPage, searchTerm);
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data");
    }
  };

  const fetchTicketList = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await getAllTickets(currentPage, searchTerm);
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data");
    }
  };

  const fetchRentalList = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await getRentalListAPI(currentPage, searchTerm);
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data");
    }
  };

  const fetchProductList = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await getProductListAPI(currentPage, searchTerm);
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data");
    }
  };

  const renderResult = (result: any): void => {
    const data = result.data;
    const page = result.page || result.pages;

    if (data) {
      setInventory(data);
      setLoading(false);
    }

    if (page) {
      setTotalRows(page.total);
    }
  };

  const onPaginate = (page: number) => {
    setCurrentPage(page);
  };

  const onChangeTab = (tab: INVENTORY_TYPE): void => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
      setCurrentPage(1);
      setLoading(true);
    }
  };

  const onSelectRow = (item: any): void => {
    setSelectedRow(item);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  // Pass the context value to the Provider component
  return (
    <InventoryContext.Provider
      value={{
        inventory,
        loading,
        totalRows,
        currentPage,
        selectedTab,
        selectedRow,
        searchTerm,

        fetchServiceList,
        fetchTicketList,
        fetchRentalList,
        fetchProductList,
        onPaginate,
        onChangeTab,
        onSelectRow,
        handleSearchChange,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
