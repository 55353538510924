import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface Props {
  showDialog: boolean;
}

const TicketPrintingOverlay: React.FC<Props> = ({
  showDialog,
}) => {
  return (
    <>
      <Modal size="sm" isOpen={showDialog}>
        <ModalHeader>
          <span>Please Wait</span>
        </ModalHeader>
        <ModalBody>
          <h4>Printing Now</h4>
          <p>Ticket is printing please wait...</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TicketPrintingOverlay;
