import { GlobalVar } from "apis/core/GlobalVar";
import axios from "apis/core/Axios";

export const getAllTickets = async (page: number = 1, q: string = "") => {
    const query = q ? `&q=${q}` : "";
    const response = await axios.get(`/tickets?page=${page}${query}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getProductListAPI = async (page: number = 1, q: string = "") => {
    const query = q ? `&q=${q}` : "";
    const response = await axios.get(`/products?page=${page}${query}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getServiceListAPI = async (page: number = 1, q: string = "") => {
    const query = q ? `&q=${q}` : "";
    
    const response = await axios.get(`/services?page=${page}${query}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getRentalListAPI = async (page: number = 1, q: string = "") => {
    const query = q ? `&q=${q}` : "";
    const response = await axios.get(`/rentals?page=${page}${query}`, { headers: GlobalVar.Http });
    return response.data;
}
