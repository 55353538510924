import {
  checkPrinterStatus,
  createOrderTicketAPI,
  printTicketAPI,
} from "apis/Inventory/TicketAPI";
import SelectPaymentMethod from "components/features/SelectPaymentMethod";
import SelectTicket from "components/features/SelectTicket";
import { TicketOrderContext } from "contexts/Purchase/TicketOrderContext";
import ReusableModal from "core/dialog.service";
import { Helper } from "core/helper";
import { showToastDanger } from "core/toast.service";
import { TicketItems, TicketOrder } from "models/Tickets/ticket-order.model";
import { Ticket } from "models/Tickets/ticket.model";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import TicketPrintingOverlay from "./Ticket/TicketPrintingOverlay";

const INITIAL_TICKET_ORDER: TicketOrder = {
  amount_received: 0,
  items: [],
  date: Helper.getCurrentDate(),
  time: Helper.getCurrentTime(),
  discount: 0,
  order_number: Helper.generateOrderNumber(),
  payment_type: "cash",
  total: 0,
};

interface Props {
  closeTicketForm: (status: boolean) => void;
  selectedTicket: Ticket | undefined;
}

const TicketForm: React.FC<Props> = ({ closeTicketForm, selectedTicket }) => {
  const [data, setData] = useState<TicketOrder>(INITIAL_TICKET_ORDER);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [showPrintingOverlay, setShowPrintingOverlay] = useState(false);
  const [loadingSubmitForm, setLoadingSubmitForm] = useState(false);

  const { fetchTicketLogs } = useContext(TicketOrderContext);

  useEffect(() => {
    if (selectedTicket) {
      const updated_items = [
        {
          id: selectedTicket.id,
          color: selectedTicket.color,
          name: selectedTicket.name,
          specification: selectedTicket.specification,
          price: selectedTicket.price,
          qty: 1,
        },
      ];

      setData((prev) => ({
        ...prev,
        items: updated_items,
        amount_received: getOverallTotal(updated_items),
        total: getOverallTotal(updated_items),
        order_number: Helper.generateOrderNumber(),
      }));
    }
  }, []);

  const onChangePaymentType = (event: any): void => {
    const payment_type = event.value;
    setData((prev) => ({
      ...prev,
      payment_type,
    }));
  };

  const onChangeAmount = (amount_received: string): void => {
    if (!Helper.validateDecimalInput(amount_received)) {
      return;
    }

    setData((prev) => ({
      ...prev,
      amount_received: amount_received ? parseFloat(amount_received) : "",
    }));
  };

  const onAddQty = (index: number): void => {
    data.items[index].qty++;
    const updated_items = data.items;

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      discount: 0,
      amount_received: getOverallTotal(updated_items),
    }));
  };

  const onMinusQty = (index: number): void => {
    if (data.items[index].qty <= 1) {
      return;
    }

    data.items[index].qty--;

    const updated_items = data.items;

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      discount: 0,
      amount_received: getOverallTotal(updated_items),
    }));
  };

  const onChangeDiscount = (discount: string = "0"): void => {
    if (!Helper.validateDecimalInput(discount)) {
      return;
    }
    const discountedValue =
      (Number(discount) / 100) * getOverallTotal(data.items);
    const result = getOverallTotal(data.items) - discountedValue;
    const discountedTotal = Helper.formatToTwoDecimal(result);

    setData((prev) => ({
      ...prev,
      discount: discount ? parseFloat(discount) : "",
      total: discountedTotal,
    }));
  };

  const onChangeTotal = (total: string = "0"): void => {
    if (!Helper.validateDecimalInput(total)) {
      return;
    }

    let discount = Helper.calculateDiscount(
      getOverallTotal(data.items),
      parseFloat(total)
    );

    setData((prev) => ({
      ...prev,
      discount: discount ? discount : "",
      total: total ? parseFloat(total) : "",
    }));
  };

  const onChangeQty = (index: number, qty: string): void => {
    data.items[index].qty = Number(qty);
    const updated_items = data.items;

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      discount: 0,
      amount_received: getOverallTotal(updated_items),
    }));
  };

  const onChangeTicket = (ticket: Ticket): void => {
    const exist = data.items.find((item) => item.id === ticket.id);
    let updated_items: any;
    if (exist) {
      exist.qty++;
      updated_items = data.items;
    } else {
      updated_items = [
        {
          id: ticket.id,
          color: ticket.color,
          name: ticket.name,
          specification: ticket.specification,
          price: ticket.price,
          qty: 1,
        },
        ...data.items,
      ];
    }

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      amount_received: getOverallTotal(updated_items),
      discount: 0,
    }));
  };

  const onClickSubmit = async (): Promise<void> => {
    setFormSubmitted(true);

    if (!isFormValid()) {
      return;
    }

    setShowConfirmSubmit(true);
  };

  const onSubmitForm = async (confirm: boolean): Promise<void> => {
    setShowConfirmSubmit(false);

    try {
      const result = await checkPrinterStatus();

      if (result.success) {
        if (confirm) {
          try {
            setLoadingSubmitForm(true);

            await createOrderTicketAPI({
              ...data,
              time: Helper.getCurrentTime(),
            });
            fetchTicketLogs();

            setShowPrintingOverlay(true);
            await printTicketAPI(data);

            // WORKAROUND TO ADD OVERLAY WHILE PRINTING, 800 * NUMBER OF TICKETS FOR TIMEOUT
            let timeoutId;
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
              setShowPrintingOverlay(false);
              closeTicketForm(true);
            }, 800 * getTotalTicketsToPrint());
          } catch (e) {
            showToastDanger("Error", "There was a problem submitting the data");
          } finally {
            setLoadingSubmitForm(false);
          }
        }
      }
    } catch (e) {
      showToastDanger("Error", "Printer not found");
    }
  };

  const revertToOriginalTotal = (): void => {
    setData((prev) => ({
      ...prev,
      discount: 0,
      total: getOverallTotal(data.items),
      amount_received: getOverallTotal(data.items),
    }));
  };

  const removeTicket = (id: string): void => {
    const updated_items = data.items.filter(
      (item: TicketItems) => item.id !== id
    );
    setData((prev) => ({
      ...prev,
      items: updated_items,
      discount: 0,
      total: getOverallTotal(updated_items),
      amount_received: getOverallTotal(updated_items),
    }));
  };

  const isFormValid = (): boolean => {
    if (!data.amount_received || !data.total) {
      return false;
    }
    return true;
  };

  const getOverallTotal = (items: TicketItems[]): number => {
    if (items.length === 0) {
      return 0;
    }
    return items.reduce(
      (total: any, item: any) => total + item.qty * item.price,
      0
    );
  };

  const hasAmountChange = (): boolean => {
    return data.amount_received > data.total;
  };

  const getTotalTicketsToPrint = () => {
    if (data.items.length === 0) {
      return 0;
    }
    const result = data.items.reduce((acc, ticket) => acc + ticket.qty, 0);
    return result;
  };

  return (
    <>
      <Modal size="lg" isOpen={true} toggle={() => closeTicketForm(false)}>
        <ModalHeader toggle={() => closeTicketForm(false)}>
          Ticket Form
        </ModalHeader>
        <ModalBody>
          {
            <>
              <FormGroup>
                <Label className="font-weight-bold">Select Ticket</Label>
                <SelectTicket onChangeTicket={onChangeTicket} />
              </FormGroup>
              <table
                className="table table-bordered text-center"
                style={{ tableLayout: "fixed" }}
              >
                <thead>
                  <tr className="table-active">
                    <th style={{ width: "60px" }}></th>
                    <th style={{ width: "180px" }}>Ticket</th>
                    <th>Price</th>
                    <th style={{ width: "180px" }}>Quantity</th>
                    <th>Subtotal</th>
                    <th style={{ width: "60px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.length > 0 &&
                    data.items.map((item: TicketItems, index: number) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <a
                              href="#"
                              className="btn btn-default btn-sm btn-icon"
                              style={{ backgroundColor: item.color }}
                            >
                              <i className="fa fa-expand invisible"></i>
                            </a>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <b className="text-uppercase">{item.name}</b>
                              <small className="text-uppercase">
                                {item.specification}
                              </small>
                            </div>
                          </td>
                          <td>{Helper.numToFixed(item.price)}</td>
                          <td>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <button
                                  onClick={() => onMinusQty(index)}
                                  className="btn btn-white px-3"
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                              </div>
                              <Input
                                onChange={(e) =>
                                  onChangeQty(index, e.target.value)
                                }
                                style={{ width: "60%", margin: "0 auto" }}
                                className="text-center font-weight-bold"
                                value={item.qty}
                              />
                              <div className="input-group-append">
                                <button
                                  onClick={() => onAddQty(index)}
                                  className="btn btn-white px-3"
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>{Helper.numToFixed(item.price * item.qty)}</td>
                          <td>
                            <button
                              disabled={data.items.length === 1}
                              onClick={() => removeTicket(item.id)}
                              className="btn btn-sm btn-danger"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr className="table-active">
                    <td colSpan={3}></td>
                    <td>
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <span
                            className="mr-2 font-weight-bold"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Discount (%):
                          </span>
                          <Input
                            type="number"
                            onChange={(e) => onChangeDiscount(e.target.value)}
                            className="text-center font-weight-bold"
                            value={data.discount}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <span className="mr-2 font-weight-bold">Total:</span>
                          <Input
                            type="number"
                            onChange={(e) => onChangeTotal(e.target.value)}
                            className="text-center font-weight-bold"
                            value={data.total}
                            invalid={formSubmitted && !data.total}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-white"
                        onClick={() => revertToOriginalTotal()}
                      >
                        <i className="fa fa-redo"></i>
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <Row>
                <Col className="col-md-6">
                  <FormGroup className="form-group mb-3">
                    <Row>
                      <Col className="col-sm-6">
                        <Label for="username" className="col-form-label">
                          Order No:
                        </Label>
                      </Col>
                      <Col className="col-sm-6">
                        <Input
                          type="text"
                          value={data.order_number}
                          className="form-control font-weight-bold"
                          readOnly={true}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group mb-3">
                    <Row>
                      <Col className="col-sm-6">
                        <Label for="username" className="col-form-label">
                          Amount Received
                        </Label>
                      </Col>
                      <Col className="col-sm-6">
                        <Input
                          type="number"
                          onChange={(e) => onChangeAmount(e.target.value)}
                          value={data.amount_received}
                          className="form-control font-weight-bold"
                          invalid={formSubmitted && !data.amount_received}
                        />
                        <FormFeedback>Amount is required.</FormFeedback>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="form-group mb-3">
                    <Row>
                      <Col className="col-sm-6">
                        <Label for="username" className="col-form-label">
                          Payment Type
                        </Label>
                      </Col>
                      <Col className="col-sm-6">
                        <SelectPaymentMethod
                          onChangePaymentType={onChangePaymentType}
                          selectedOption={data.payment_type}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {hasAmountChange() || data.total > data.amount_received ? (
                    <>
                      <FormGroup className="form-group mb-3">
                        <Row className="mb-3 align-items-center">
                          <Label className="col-sm-6 mb-0 font-weight-bold">
                            {hasAmountChange() ? "Change" : "Balance"}
                          </Label>
                          <div className="col-sm-6">
                            <Label>
                              <>
                                {hasAmountChange() ? (
                                  <span>
                                    {Helper.formatToTwoDecimal(
                                      +data.amount_received - +data.total
                                    )}
                                  </span>
                                ) : (
                                  <span className="font-weight-bold">
                                    {Helper.formatToTwoDecimal(
                                      +data.total - +data.amount_received
                                    )}
                                  </span>
                                )}
                              </>
                            </Label>
                          </div>
                        </Row>
                      </FormGroup>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col className="col-md-6"></Col>
              </Row>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            disabled={loadingSubmitForm}
            color="white"
            onClick={() => closeTicketForm(false)}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            disabled={loadingSubmitForm}
            color="success"
            onClick={() => onClickSubmit()}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <ReusableModal
        title="Submit Ticket"
        message="To proceed, please confirm that you understand this action cannot be undone and that a ticket will be printed."
        showDialog={showConfirmSubmit}
        closeDialog={onSubmitForm}
      />

      <TicketPrintingOverlay showDialog={showPrintingOverlay} />
    </>
  );
};

export default TicketForm;
