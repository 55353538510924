import React from 'react';
import { Navigate } from 'react-router-dom';
import Inventory from "../pages/Inventory";
import Personnel from "../pages/Personnel";
import Purchase from "../pages/Purchase";
import SalesReport from "../pages/SalesReport";
import ActivityLogs from './../pages/ActivityLogs';
import Login from './../pages/auth/Login';


const routes = (token) => [
  {
    path: '/',
    title: 'Login',
    element: <Login />
  },
  {
    path: '/login',
    title: 'Login',
    element: <Login />
  },
  {
    path: '/events-and-orders',
    title: 'Purchase & Orders',
    element: token ? <Purchase /> : <Navigate to="/login" replace />
  },
  // {
  //   path: '/calendar',
  //   title: 'Calendar',
  //   element: token ? <Calendar /> : <Navigate to="/login" replace/>
  // },
  {
    path: '/tickets',
    title: 'Tickets',
    element: token ? <Inventory /> : <Navigate to="/login" replace/>
  },
  // {
  //   path: '/sales-report',
  //   title: 'Sales Report',
  //   element: token ? <SalesReport /> : <Navigate to="/login" replace/>
  // },
  // {
  //   path: '/analytics',
  //   title: 'Analytics',
  //   element: token ? <Analytics /> : <Navigate to="/login" replace />
  // },
  // {
  //   path: '/activity-logs',
  //   title: 'Activity Logs',
  //   element: token ? <ActivityLogs /> : <Navigate to="/login" replace/>
  // },
  {
    path: '/users',
    title: 'Users',
    element: token ? <Personnel /> : <Navigate to="/login" replace/>
  },
  {
    path: '*',
    element: token ? <Navigate to="/events-and-orders" replace /> : <Navigate to="/login" replace/>
  }
];


export default routes;