import React from "react";
import { Input } from "reactstrap";

interface Props {
  onChangeTime: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedTime: string;
}

const SelectTimePicker: React.FC<Props> = ({ onChangeTime, selectedTime }) => {
  return <Input type="time" value={selectedTime} onChange={onChangeTime} />;
};

export default SelectTimePicker;
