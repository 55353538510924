import React, { useState } from "react";

import Calendar from "components/features/Calendar";
import { MockInventoryType } from "fake/mock-inventory-type";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ProductLogs from "./components/Purchases/ProductLogs";
import ProductsTab from "./components/Purchases/ProductsTab";
import RentalLogs from "./components/Purchases/RentalLogs";
import RentalTab from "./components/Purchases/RentalTab";
import ServiceLogs from "./components/Purchases/ServiceLogs";
import ServiceTab from "./components/Purchases/ServiceTab";
import TicketLogs from "./components/Purchases/TicketLogs";
import TicketsTab from "./components/Purchases/TicketsTab";
import { ServiceOrderProvider } from "contexts/Purchase/ServiceOrderContext";
import { ProductOrderProvider } from "contexts/Purchase/ProductOrderContext";
import { RentalOrderProvider } from "contexts/Purchase/RentalOrderContext";
import { TicketOrderProvider } from "contexts/Purchase/TicketOrderContext";
import { CalendarProvider } from "contexts/CalendarContext";

const Purchase = () => {
  const [activeTab, setActiveTab] = useState("1");

  const [inventoryType, setInventoryType] = useState<any>(MockInventoryType);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <h3>Events</h3>
          <div className="card">
            <div className="card-body">
              <CalendarProvider>
                <Calendar />
              </CalendarProvider>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <h3>Ticket Order</h3>
          <div className="card mb-3">
            <div className="card-body">
              {/* <Nav tabs>
                {inventoryType.map((item: any, index: number) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={
                          item.id === activeTab
                            ? "active cursor-pointer"
                            : "cursor-pointer"
                        }
                        onClick={() => {
                          toggle(item.id);
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav> */}
              <TabContent activeTab={activeTab} className="mb-0">
                {inventoryType.map((tab: any, index: number) => {
                  return (
                    <TabPane key={index} tabId={tab.id}>
                      {activeTab === tab.id && (
                        <Row>
                          <Col sm="12">
                            {tab.type === "ticket" ? (
                              <TicketOrderProvider>
                                <TicketsTab />
                                <hr />
                                <TicketLogs />
                              </TicketOrderProvider>
                            ) : (
                              <></>
                            )}
                            {/* {tab.type === "product" ? (
                              <ProductOrderProvider>
                                <ProductsTab />
                                <hr />
                                <ProductLogs />
                              </ProductOrderProvider>
                            ) : (
                              <></>
                            )}
                            {tab.type === "service" ? (
                              <ServiceOrderProvider>
                                <ServiceTab />
                                <hr />
                                <ServiceLogs />
                              </ServiceOrderProvider>
                            ) : (
                              <></>
                            )}
                            {tab.type === "rental" ? (
                              <RentalOrderProvider>
                                <RentalTab />
                                <hr />
                                <RentalLogs />
                              </RentalOrderProvider>
                            ) : (
                              <></>
                            )} */}
                          </Col>
                        </Row>
                      )}
                    </TabPane>
                  );
                })}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
