import {
  activateUserAPI,
  deactivateUserAPI,
  deleteUserAPI,
} from "apis/UsersAPI";
import TablePaginationItem from "components/features/TablePaginationItem";
import { UsersContext } from "contexts/UsersContext";
import ReusableModal from "core/dialog.service";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { User } from "models/Users/user.model";
import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import PersonnelForm from "./PersonnelForm";

const PersonnelTable = () => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean[]>([false, false]);
  const {
    users,
    loading,
    showUserForm,
    selectedRow,
    totalRows,
    currentPage,
    toggleUserForm,
    onSelectRow,
    fetchUsersList,
    onPaginate,
  } = useContext(UsersContext);
  const countPerPage = 10;

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handlePaginate = (e: any, page: number) => {
    e.preventDefault();
    onPaginate(page);
  };

  const editUser = (user: User): void => {
    onSelectRow({ ...user, password: "" });
    toggleUserForm(true);
  };

  const confirmDeleteUser = async (confirm: boolean): Promise<void> => {
    setShowConfirmDeleteModal(false);
    if (confirm) {
      try {
        await deleteUserAPI(selectedRow.id);
        showToastSuccess("Success", "User deleted successfully");
        fetchUsersList();
      } catch (e) {
        showToastDanger("Error", "There was an error deleting the user");
      }
    }
  };

  const deleteUser = (user: User): void => {
    onSelectRow(user);
    setShowConfirmDeleteModal(true);
  };

  const toggleDropdown = (index: number): void => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  const handleChangeStatus = async (user: User): Promise<void> => {
    try {
      if (user.status === "active") {
        await activateUserAPI(user.id);
        showToastSuccess("Success", "User account status set to active");
      } else {
        await deactivateUserAPI(user.id);
        showToastSuccess("Success", "User account status set to inactive");
      }
    } catch (e) {
      showToastDanger("Error", "There was an error updating the user status");
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <table className="table table-bordered mb-0 text-center">
            <thead>
              <tr className="table-active">
                <th>Name</th>
                <th>Type</th>
                <th>Username</th>
                <th>Notes</th>
                <th>Color</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((item: User, index: number) => {
                return (
                  <tr key={item.id}>
                    <td className="font-weight-bold">{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.username}</td>
                    <td>{item.notes}</td>
                    <td>
                      <a
                        href="#"
                        className="btn btn-danger btn-sm btn-icon btn-circle"
                        style={{ backgroundColor: item.color }}
                      >
                        <i className="fa fa-times invisible"></i>
                      </a>
                    </td>
                    <td>
                      {localStorage.getItem("role") === "admin" ? (
                        <Dropdown
                          isOpen={dropdownOpen[index]}
                          toggle={() => toggleDropdown(index)}
                        >
                          <DropdownToggle color="white">Edit</DropdownToggle>
                          <DropdownMenu positionFixed={true}>
                            <DropdownItem onClick={() => editUser(item)}>
                              Edit User
                            </DropdownItem>
                            <DropdownItem onClick={() => deleteUser(item)}>
                              Delete User
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {totalRows > countPerPage ? (
            <div className="mt-3">
              <TablePaginationItem
                totalRows={totalRows}
                currentPage={currentPage}
                handleClick={handlePaginate}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Skeleton height={40} count={10} style={{ marginBottom: "10px" }} />
      )}
      {showUserForm ? (
        <PersonnelForm
          showPersonnelForm={showUserForm}
          closePersonnelForm={() => toggleUserForm(false)}
        />
      ) : (
        <></>
      )}

      <ReusableModal
        title="Delete User"
        message="Are you sure you want to delete this user? Please confirm to proceed."
        showDialog={showConfirmDeleteModal}
        closeDialog={confirmDeleteUser}
      />
    </>
  );
};

export default PersonnelTable;
