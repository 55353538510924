import { InventoryContext } from "contexts/InventoryContext";
import { INVENTORY_TYPE } from "core/contants";
import { MockInventoryType } from "fake/mock-inventory-type";
import React, { useContext, useState } from "react";
import { Button, Col, Row, TabContent, TabPane } from "reactstrap";
import InventoryForm from "./InventoryForm";
import InventoryTable from "./InventoryTable";

const InventoryContainer = () => {
  const [showInventoryForm, setShowInventoryForm] = useState(false);
  const [inventoryType, setInventoryType] = useState<any>(MockInventoryType);

  const { loading, selectedTab, onChangeTab, onSelectRow } =
    useContext(InventoryContext);

  const closeInventoryForm = (status: boolean): void => {
    setShowInventoryForm(false);
  };

  const toggle = (tab: INVENTORY_TYPE) => {
    if (selectedTab !== tab) {
      onChangeTab(tab);
    }
  };

  const addNew = () => {
    onSelectRow(null);
    setShowInventoryForm(true);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-column mb-3">
            {!loading ? (
              <>
                <div className="mb-3 d-flex">
                  <div className="mr-auto">
                    <Button
                      size="lg"
                      onClick={() => addNew()}
                      className="btn btn-teal"
                    >
                      Add New
                    </Button>
                  </div>

                  <div></div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div>
              <TabContent activeTab={selectedTab}>
                {inventoryType.map((tab: any, index: number) => {
                  return (
                    <TabPane key={index} tabId={tab.type}>
                      {selectedTab === tab.type && (
                        <Row>
                          <Col sm="12">
                            <InventoryTable />
                          </Col>
                        </Row>
                      )}
                    </TabPane>
                  );
                })}
              </TabContent>
            </div>
          </div>
        </div>
      </div>

      {showInventoryForm && (
        <InventoryForm closeInventoryForm={closeInventoryForm} />
      )}
    </>
  );
};

export default InventoryContainer;
