import FullCalendar, { CalendarApi, DatesSetArg } from "@fullcalendar/react";
import {
  getCalendarNotesDailyAPI,
  getCalendarNotesMonthlyAPI,
  getCalendarNotesWeeklyAPI,
} from "apis/CalendarAPI";
import { GlobalVar } from "apis/core/GlobalVar";
import { CalendarViewType } from "core/contants";
import { Helper } from "core/helper";
import { showToastDanger } from "core/toast.service";
import { CalendarNote } from "models/Calendar/calendar-note.model";
import React, {
  createContext,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

interface CalendarContextData {
  calendarEvents: CalendarNote[];
  selectedNote: CalendarNote | undefined;
  selectedDate: string | undefined;
  loading: boolean;

  calendarRef: RefObject<FullCalendar>;
  handleDatesSet: (arg: DatesSetArg) => void;
  rerenderCalendar: () => void;
  onSelectNote: (note: any) => void;
  onSelectDate: (date: string) => void;
}

interface CalendarProviderProps {
  children: React.ReactNode;
}

export const CalendarContext = createContext<CalendarContextData>({
  calendarEvents: [],
  selectedNote: undefined,
  selectedDate: Helper.getCurrentDate(),
  calendarRef: { current: null },
  loading: true,

  handleDatesSet: () => {},
  rerenderCalendar: () => {},
  onSelectNote: () => {},
  onSelectDate: () => {},
});

export const CalendarProvider: React.FC<CalendarProviderProps> = ({
  children,
}) => {
  const calendarRef: RefObject<FullCalendar> = useRef<FullCalendar>(null);

  const [calendarEvents, setCalendarEvents] = useState<CalendarNote[]>([]);
  const [selectedNote, setSelectedNote] = useState<CalendarNote | undefined>(
    undefined
  );

  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    Helper.getCurrentDate()
  );

  useEffect(() => {
    rerenderCalendar();
  }, []);

  const [loading, setLoading] = useState(true);

  const onSelectNote = (note: CalendarNote): void => {
    setSelectedNote(note);
    setSelectedDate(undefined); // on click of event, set the selectedDate to undefined
  };

  const onSelectDate = (date: string): void => {
    setSelectedNote(undefined);
    setSelectedDate(date); // on click of date, set the selectedDate to its click value
  };

  const handleDatesSet = (arg: DatesSetArg) => {
    // console.log("Dates set:", arg.start, arg.end);
    if (!GlobalVar.Http) {
      return;
    }
    const type = arg.view.type;

    renderCalendar(type, arg.startStr, arg.endStr);
  };

  const renderCalendar = (type: string, start: string, end: string): void => {
    callMonthly(start);
    // if (type === CalendarViewType.timeGridDay) {
    //   callDaily(start);
    // } else if (type === CalendarViewType.timeGridWeek) {
    //   callWeekly(start, end);
    // } else if (type === CalendarViewType.dayGridMonth) {
    //   callMonthly(start);
    // }
  };

  const getCalendarInstance = (): CalendarApi | undefined => {
    if (calendarRef.current !== null) {
      const calendarApi = calendarRef.current.getApi();
      return calendarApi;
    }
  };

  const getCalendarView = (): string | undefined => {
    const calendar = getCalendarInstance();
    if (calendar) {
      return calendar.view.type;
    }
  };

  const callDaily = async (date: string): Promise<void> => {
    console.log("Daily", date);
    const result = await getCalendarNotesDailyAPI(Helper.ISOToMMDDYYYY(date));
    setCalendarEvents(applyDateTime(result.data));
  };

  const callWeekly = async (start: string, end: string): Promise<void> => {
    console.log("Weekly", start, "End", end);
    const result = await getCalendarNotesWeeklyAPI(
      Helper.ISOToMMDDYYYY(start),
      Helper.ISOToMMDDYYYY(end)
    );
    setCalendarEvents(applyDateTime(result.data));
  };

  const callMonthly = async (date: string): Promise<void> => {
    console.log("Monthly", date);

    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();

    try {
      const result = await getCalendarNotesMonthlyAPI(month, year);
      if (result.data.length > 0) {
        setCalendarEvents(applyDateTime(result.data));
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      // showToastDanger("Error", "There was an error loading the calendar");
    }
  };

  const applyDateTime = (data: CalendarNote[]) => {
    const result = data.map((rows: CalendarNote) => ({
      ...rows,
      start: `${rows.date_start}T${rows.time_start}`,
      end: `${rows.date_end}T${rows.time_end}`,
      backgroundColor: `${rows.color}`,
      id: `${rows.id}`,
    }));

    return result;
  };

  const rerenderCalendar = (): void => {
    const calendar = getCalendarInstance();

    if (calendar) {
      const type = getCalendarView();

      const startStr = calendar.view.currentStart;
      const endStr = calendar.view.currentEnd;

      if (type && startStr && endStr) {
        renderCalendar(
          type,
          Helper.appendEightHoursToDate(startStr).toISOString(),
          Helper.appendEightHoursToDate(endStr).toISOString()
        );
      }
    }
  };

  return (
    <CalendarContext.Provider
      value={{
        calendarEvents,
        calendarRef,
        selectedNote,
        selectedDate,
        loading,

        handleDatesSet,
        rerenderCalendar,
        onSelectNote,
        onSelectDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
