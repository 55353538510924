
import { User } from "models/Users/user.model";
import axios from "./core/Axios";
import { GlobalVar } from "./core/GlobalVar";


export const getUsersListAPI = async () => {
    const response = await axios.get(`/users`, { headers: GlobalVar.Http });
    return response.data;
}

export const getUserRolesAPI = async () => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get(`https://api.npoint.io/43fedb461769cb559e30`);
    return response.data;
}

export const createUserAPI = async (user: User) => {
    const response = await axios.post("/user", user, { headers: GlobalVar.Http });
    return response.data;
}

export const updateUserAPI = async (user: User) => {
    const response = await axios.put(`/user/${user.id}`, user, { headers: GlobalVar.Http });
    return response.data;
}

export const deleteUserAPI = async (user_id: string) => {
    const response = await axios.delete(`/user/${user_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const deactivateUserAPI = async (id: string) => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.delete(`/user-status/${id}`);
    return response.data;
}

export const activateUserAPI = async (id: string) => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.put(`/user-status/${id}`);
    return response.data;
}