
import { GlobalVar } from "apis/core/GlobalVar";
import axios from "apis/core/Axios";

export const createProductAPI = async (data: any) => {
    const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    return response.data;
}

export const updateProductAPI = async (id: string, data: any) => {
    const response = await axios.post(`product/${id}`, data, { headers: GlobalVar.Http });
    return response.data;
}

export const getAllProductsAPI = async () => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get("https://api.npoint.io/a2e148d790e897b760f5");
    return response.data;
}

export const deleteProductAPI = async (product_id: string) => {
    const response = await axios.delete(`/product/${product_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getProductLogsAPI = async (page: number) => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get(`https://api.npoint.io/e677561b52ba99c43049?page=${page}`);
    return response.data;
}

export const getProductsListAPI = async () => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get("https://api.npoint.io/81529daab71919f97241");
    return response.data;
}


export const createOrderProductAPI = async (data: any) => {
    const response = await axios.post("/order", data, { headers: GlobalVar.Http });
    return response.data;
}


export const getOrderIdByProductAPI = async () => {
    const response = await axios.get("https://api.npoint.io/e907410965a527f3103f");
    // const response = await axios.get(`/order/ticket/transaction-number`, { headers: GlobalVar.Http });
    return response.data;
}