import React, { Component, createContext } from 'react'
import { GlobalVar } from '../apis/core/GlobalVar';

export const AuthContext = createContext();

class AuthContextProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: localStorage.getItem("token")
        }
    }

    setToken = (token) => {
        console.log('auth update', token)
        this.storeToken(token);
        this.setHttpHeaders(token);
        this.setState({ token });
    }

    storeToken = (token) => {
        if (!token) {
            localStorage.removeItem("token")
            return;
        }
        localStorage.setItem("token", token)
    }

    setHttpHeaders = (token) => {
        GlobalVar.setHeader(token);
    }

    componentDidMount() {
        GlobalVar.setHeader(this.state.token);
        console.log('auth context here', this.state.token)
    }

    render() {
        return (
            <AuthContext.Provider value={{ ...this.state, setToken: this.setToken }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthContextProvider;