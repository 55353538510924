import { TicketOrderContext } from "contexts/Purchase/TicketOrderContext";
import { Ticket } from "models/Tickets/ticket.model";
import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import TicketForm from "./TicketForm";

const TicketsTab = () => {
  const navigate = useNavigate();

  const [showTicketForm, setShowTicketForm] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | undefined>(
    undefined
  );

  const { tickets, loading } = useContext(TicketOrderContext);

  const openTicketForm = (ticket: Ticket): void => {
    setShowTicketForm(true);
    setSelectedTicket(ticket);
  };

  return (
    <div>
      <div className="row">
        {tickets.length > 0 &&
          !loading &&
          tickets.map((ticket: Ticket) => {
            return (
              <div key={ticket.id} className="col-md-4">
                <div
                  onClick={() => openTicketForm(ticket)}
                  className="widget widget-stats cursor-pointer"
                  style={{ backgroundColor: ticket.color }}
                >
                  <div className="stats-info text-center">
                    <h4 className="text-uppercase text-nowrap">
                      {ticket.specification}
                    </h4>
                    <p>{ticket.price}</p>
                  </div>
                  <div className="stats-link text-nowrap">
                    <a href="#">{ticket.name}</a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {tickets.length === 0 && !loading ? (
        <>
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{ minHeight: "250px" }}
          >
            <i className="fa fa-file-invoice fa-3x mb-2"></i>
            <p className="text-center">
              Start adding tickets to the display here.
            </p>
            <button
              className="btn btn-white"
              onClick={() => navigate("/inventory")}
            >
              Add Ticket
            </button>
          </div>
        </>
      ) : (
        <></>
      )}

      {loading ? (
        <div className="row">
          <div className="col-4">
            <Skeleton height={100} />
          </div>
          <div className="col-4">
            <Skeleton height={100} />
          </div>
          <div className="col-4 mb-3">
            <Skeleton height={100} />
          </div>
          <div className="col-4">
            <Skeleton height={100} />
          </div>
          <div className="col-4">
            <Skeleton height={100} />
          </div>
          <div className="col-4">
            <Skeleton height={100} />
          </div>
        </div>
      ) : (
        <></>
      )}

      {showTicketForm && (
        <TicketForm
          selectedTicket={selectedTicket}
          closeTicketForm={() => setShowTicketForm(false)}
        />
      )}
    </div>
  );
};

export default TicketsTab;
