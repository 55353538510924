
import axios from "./core/Axios";
import { GlobalVar } from "./core/GlobalVar";

const loginUser = async (data: any) => {
    const response = await axios.post("/login", data, { headers: GlobalVar.Http });
    return response.data;
}


export { loginUser };