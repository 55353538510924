import { getServiceListAPI } from "apis/Inventory/InventoryAPI";
import { getUsersListAPI } from "apis/UsersAPI";
import { APP_COLORS } from "core/contants";
import { Helper } from "core/helper";
import { showToastDanger } from "core/toast.service";
import { Service } from "models/Services/service-model";
import { User } from "models/Users/user.model";
import React, { createContext, useEffect, useState } from "react";

interface UsersContextData {
  users: User[];
  loading: boolean;
  totalRows: number;
  currentPage: number;
  selectedRow: User;
  showUserForm: boolean;
  formValues: User;

  fetchUsersList: () => void;
  onPaginate: (page: number) => void;
  onSelectRow: (item: any) => void;
  toggleUserForm: (status: boolean) => void;
  handleFormChange: (event: any) => void;
  onChangeUserType: (selected: any) => void;
  onChangeColor: (color: string) => void;
}

interface UsersProviderProps {
  children: React.ReactNode;
}

export const DEFAULT_USER = {
  id: "",
  color: APP_COLORS[0],
  name: "",
  notes: "",
  status: "active",
  type: "admin",
  username: "",
  password: "",
};

export const UsersContext = createContext<UsersContextData>({
  users: [],
  loading: true,
  totalRows: 0,
  currentPage: 1,
  selectedRow: DEFAULT_USER,
  showUserForm: false,
  formValues: DEFAULT_USER,

  fetchUsersList: () => {},
  onPaginate: () => {},
  onSelectRow: () => {},
  toggleUserForm: () => {},
  handleFormChange: () => {},
  onChangeUserType: () => {},
  onChangeColor: () => {},
});

export const UsersProvider: React.FC<UsersProviderProps> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(DEFAULT_USER);
  const [showUserForm, setShowUserForm] = useState(false);
  const [formValues, setFormValues] = useState(DEFAULT_USER);

  useEffect(() => {
    fetchUsersList();
  }, [currentPage]);

  const fetchUsersList = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await getUsersListAPI();
      renderResult(result);
    } catch (e) {
      showToastDanger("Error", "There was an error loading the data.");
    }
  };

  const onPaginate = (page: number): void => {
    setCurrentPage(page);
  };

  const onSelectRow = (item: User): void => {
    setSelectedRow(item);
    setFormValues(item);
  };

  const toggleUserForm = (status: boolean): void => {
    setShowUserForm(status);
  };

  const renderResult = (result: any): void => {
    const data = result.data;
    const page = result.page || result.pages;

    if (data) {

      setUsers(data);
      setLoading(false);
    }

    if (page) {
      setTotalRows(page.total);
    }
  };

  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    console.log(value);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const onChangeUserType = (selected: any): void => {
    console.log(selected);
    setFormValues((prev) => ({
      ...prev,
      type: selected.value,
    }));
  };

  const onChangeColor = (color: string): void => {
    console.log(color);
    setFormValues((prev) => ({
      ...prev,
      color,
    }));
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        loading,
        totalRows,
        currentPage,
        selectedRow,
        showUserForm,
        formValues,

        fetchUsersList,
        onPaginate,
        onSelectRow,
        toggleUserForm,
        handleFormChange,
        onChangeUserType,
        onChangeColor,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
