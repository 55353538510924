import { deleteProductAPI } from "apis/Inventory/ProductAPI";
import { deleteRentalAPI } from "apis/Inventory/RentalAPI";
import { deleteServiceAPI } from "apis/Inventory/ServiceAPI";
import { deleteTicketByIdAPI } from "apis/Inventory/TicketAPI";
import TablePaginationItem from "components/features/TablePaginationItem";
import { InventoryContext } from "contexts/InventoryContext";
import { INVENTORY_TYPE } from "core/contants";
import ReusableModal from "core/dialog.service";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { InventoryTableModel } from "models/Inventory/inventory-table.model";
import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import InventoryForm from "./InventoryForm";

const COUNT_PER_PAGE = 10;

const InventoryTable = () => {
  const {
    inventory,
    loading,
    totalRows,
    currentPage,
    selectedTab,
    selectedRow,
    fetchRentalList,
    fetchProductList,
    fetchServiceList,
    fetchTicketList,
    onPaginate,
    onSelectRow,
  } = useContext(InventoryContext);

  const [dropdownOpen, setDropdownOpen] = useState<boolean[]>([false, false]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showInventoryForm, setShowInventoryForm] = useState(false);

  const toggle = (index: number): void => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  const deleteRow = (item: InventoryTableModel): void => {
    onSelectRow(item);
    setShowConfirmDeleteModal(true);
  };

  const onConfirmDelete = async (confirm: boolean): Promise<void> => {
    setShowConfirmDeleteModal(false);

    if (confirm) {
      try {
        switch (selectedTab) {
          case INVENTORY_TYPE.PRODUCT:
            await deleteProductAPI(selectedRow.id);
            fetchProductList();
            break;

          case INVENTORY_TYPE.SERVICE:
            await deleteServiceAPI(selectedRow.id);
            fetchServiceList();
            break;

          case INVENTORY_TYPE.RENTAL:
            await deleteRentalAPI(selectedRow.id);
            fetchRentalList();
            break;

          case INVENTORY_TYPE.TICKET:
            await deleteTicketByIdAPI(selectedRow.id);
            fetchTicketList();
            break;
        }

        showToastSuccess("Success", "Ticket deleted successfully.");
      } catch (e) {
        showToastDanger("Error", "There was an error deleting the data.");
      }
    }
  };

  const editRow = (item: InventoryTableModel): void => {
    onSelectRow(item);
    setShowInventoryForm(true);
  };

  const handlePaginate = (e: any, page: number): void => {
    e.preventDefault();
    onPaginate(page);
  };

  return (
    <>
      {!loading ? (
        <>
          <table
            className="table table-bordered mb-0 text-center"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr className="table-active">
                {/* <th style={{ width: "150px" }}>Date</th> */}
                <th style={{ width: "150px" }}>Name</th>
                <th style={{ width: "150px" }}>Specification</th>
                <th style={{ width: "250px" }}>Description</th>
                <th>Price</th>
                <th>Color</th>
                <th style={{ width: "200px" }}>Assigned</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((item: InventoryTableModel, index: number) => {
                return (
                  <tr key={item.id}>
                    <td className="font-weight-bold text-uppercase">
                      {item.name}{" "}
                    </td>
                    <td className="font-weight-bold text-uppercase">
                      {item.specification}{" "}
                    </td>
                    <td className="text-truncate">{item.description}</td>
                    <td className="font-weight-bold">
                      {Number(item.price).toFixed(2)}
                    </td>
                    <td>
                      <a
                        href="#"
                        className="btn btn-danger btn-icon btn-sm btn-circle"
                        style={{ backgroundColor: item.color }}
                      >
                        <i className="fa fa-times invisible"></i>
                      </a>
                    </td>
                    <td>
                      {item.assign_to ? item.assign_to.join(", ") : <></>}
                      {item.assign_roles ? item.assign_roles.join(", ") : <></>}
                    </td>
                    <td>
                      <Dropdown
                        isOpen={dropdownOpen[index]}
                        toggle={() => toggle(index)}
                      >
                        <DropdownToggle color="white">Edit</DropdownToggle>
                        <DropdownMenu positionFixed={true}>
                          <DropdownItem onClick={() => editRow(item)}>
                            Edit Item
                          </DropdownItem>
                          <DropdownItem onClick={() => deleteRow(item)}>
                            Delete Item
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {inventory.length === 0 ? (
            <>
              <p className="text-center p-15 mb-0">No data found</p>
            </>
          ) : (
            <></>
          )}

          {totalRows > COUNT_PER_PAGE ? (
            <div className="mt-3">
              <TablePaginationItem
                totalRows={totalRows}
                currentPage={currentPage}
                handleClick={handlePaginate}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Skeleton height={40} count={10} style={{ marginBottom: "10px" }} />
      )}

      <ReusableModal
        title="Delete Ticket"
        message="Are you sure you want to delete this ticket? Please confirm to proceed."
        showDialog={showConfirmDeleteModal}
        closeDialog={onConfirmDelete}
      />

      {showInventoryForm && (
        <InventoryForm closeInventoryForm={() => setShowInventoryForm(false)} />
      )}
    </>
  );
};

export default InventoryTable;
