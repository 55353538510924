import React from 'react';
import { useEffect, useContext } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext.js';
import routes from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = 'Mambusao | ' + routeArray[i].title;
		}
	}
	document.title = (pageTitle) ? pageTitle : 'Mambusao';
}

const Content = () => {
	const { token } = useContext(AuthContext);
	const appRoutes = useRoutes(routes(token));
	const navigate = useNavigate();

	useEffect(() => {
		if (!token) {
			navigate("/login");
			return;
		}
		navigate("/events-and-orders")
	}, [])


	return (
		<AuthContext.Consumer>{(authContext) => (
			<PageSettings.Consumer>
				{({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => {
					return (
						<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
							<div className='container-xxl p-0'>
								{appRoutes}
							</div>
						</div>
					)
				}
				}
			</PageSettings.Consumer>
		)}

		</AuthContext.Consumer>
	)
}

export default Content
