import { DEFAULT_USER, UsersContext } from "contexts/UsersContext";
import React, { useContext, useState } from "react";
import { Button } from "reactstrap";
import PersonnelForm from "./PersonnelForm";
import PersonnelTable from "./PersonnelTable";

const UsersContainer = () => {
  const [showPersonnelForm, setShowPersonnelForm] = useState(false);

  const { loading, onSelectRow } = useContext(UsersContext);

  const closePersonnelForm = () => {
    setShowPersonnelForm(false);
  };

  const addNew = () => {
    onSelectRow(DEFAULT_USER);
    setShowPersonnelForm(true);
  }

  return (
    <>
      <h3>Users</h3>
      <div className="card">
        <div className="card-body">
          {!loading ? (
            <>
              <div className="d-flex mb-3">
                <Button
                  size="lg"
                  onClick={() => addNew()}
                  className="btn btn-teal"
                >
                  Add New
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
          <PersonnelTable />
        </div>
      </div>

      <PersonnelForm
        showPersonnelForm={showPersonnelForm}
        closePersonnelForm={closePersonnelForm}
      />
    </>
  );
};

export default UsersContainer;
