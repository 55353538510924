import { UsersProvider } from "contexts/UsersContext";
import React, { useState } from "react";
import PersonnelForm from "./components/Personnel/PersonnelForm";
import PersonnelTable from "./components/Personnel/PersonnelTable";
import UsersContainer from "./components/Personnel/UsersContainer";

const Personnel = () => {
  const [showPersonnelForm, setShowPersonnelForm] = useState(false);

  const closePersonnelForm = () => {
    setShowPersonnelForm(false);
  };

  return (
    <UsersProvider>
      <UsersContainer />
    </UsersProvider>
  );
};

export default Personnel;
