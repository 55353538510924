import React, { useEffect, useState } from "react";
import Select from "react-select";

interface Props {
  onChangeAssigned: (event: any) => void;
  selectedOption: string[];
}

interface SelectAssignRoleModel {
  label: string;
  value: string;
}

const OPTIONS: SelectAssignRoleModel[] = [
  { label: "Secretary", value: "secretary" },
  { label: "Guard", value: "guard" },
  { label: "Admin", value: "admin" },
];

const SelectAssignedRole: React.FC<Props> = ({
  onChangeAssigned,
  selectedOption,
}: Props) => {
  const [options, setOptions] = useState<SelectAssignRoleModel[]>(OPTIONS);
  const [defaultOptions, setDefaultOptions] = useState<any>([]);

  useEffect(() => {
    console.log("***", selectedOption);

    if (selectedOption) {
      const selectedOptions = options.filter((option) =>
        selectedOption.includes(option.label)
      );

      setDefaultOptions(selectedOptions);
    }
  }, [selectedOption]);

  return (
    <Select
      isMulti={true}
      isClearable={false}
      value={defaultOptions}
      isSearchable={false}
      onChange={onChangeAssigned}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={options}
    />
  );
};

export default SelectAssignedRole;
