import React, { useState } from "react";
import { Input } from "reactstrap";

interface Props {
  onChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedDate: string;
  readOnly?: boolean;
}

const SelectDatePicker: React.FC<Props> = ({
  onChangeDate,
  selectedDate,
  readOnly,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Input
      readOnly={readOnly}
      type="date"
      value={selectedDate}
      onChange={onChangeDate}
    />
  );
};

export default SelectDatePicker;
