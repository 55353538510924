
import { GlobalVar } from "apis/core/GlobalVar";
import axios from "apis/core/Axios";

export const createRentalAPI = async (data: any) => {
    const response = await axios.post("/rental", data, { headers: GlobalVar.Http });
    return response.data;
}

export const updateRentalAPI = async (id: string, data: any) => {
    const response = await axios.put(`/rental/${id}`, data, { headers: GlobalVar.Http });
    return response.data;
}

export const getAllRentalsAPI = async () => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get("https://api.npoint.io/2827b7939f7dc8014805");
    return response.data;
}

export const deleteRentalAPI = async (rental_id: string) => {
    const response = await axios.delete(`/rental/${rental_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getRentalLogsAPI = async (page: number = 1) => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get(`https://api.npoint.io/41fcc4fc6fcfef72f2c5?page=${page}`);
    return response.data;
}