import { createOrderProductAPI } from "apis/Inventory/ProductAPI";
import { getTransactionByIdAPI } from "apis/SalesReportAPI";
import SelectDatePicker from "components/features/SelectDatePicker";
import SelectPaymentMethod from "components/features/SelectPaymentMethod";
import SelectProduct from "components/features/SelectProduct";
import { ProductOrderContext } from "contexts/Purchase/ProductOrderContext";
import ReusableModal from "core/dialog.service";
import { Helper } from "core/helper";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { PaymentType } from "models/payment-type.model";
import { Product } from "models/Products/product-model";
import { ProductOrder } from "models/Products/product-order.model";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

interface Props {
  closeProductForm: (status: boolean) => void;
  selectedTransaction: any;
}

const INITIAL_PRODUCT_ORDER: ProductOrder = {
  items: [],
  amount_received: 0,
  discount: 0,
  total: 0,
  payment_method: "cash",
  remarks: "",
  date: Helper.getCurrentDate(),
  order_number: "0000",
};

const TransactionForm: React.FC<Props> = ({
  closeProductForm,
  selectedTransaction,
}) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loadingSubmitForm, setLoadingSubmitForm] = useState(false);
  const [data, setData] = useState<ProductOrder>(INITIAL_PRODUCT_ORDER);
  const { fetchProductList } = useContext(ProductOrderContext);

  useEffect(() => {
    if (selectedTransaction) {
      const getTransactionData = async () => {
        const result = await getTransactionByIdAPI(selectedTransaction.id);

        setData((prev) => ({
          ...prev,
          items: result.data.items,
          amount_received: result.data.amount_received,
          total: result.data.total,
          discount: 0,
          remarks: result.data.remarks,
          order_number: "0000",
        }));
      };

      getTransactionData();
    }
  }, []);

  const onChangeProduct = (product: any): void => {
    const existingItemIndex = data.items.findIndex(
      (item: any) => item.id === product.id
    );

    if (existingItemIndex !== -1) {
      return;
    }

    const updated_items = [...data.items, { ...product, qty: 1 }];

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      amount_received: getOverallTotal(updated_items),
      discount: 0,
    }));
  };

  const handleAddQty = (product: any): void => {
    const updated_items = data.items.map((item: any) => {
      if (item.id === product.id) {
        item = { ...item, qty: ++item.qty };
        return item;
      }
      return item;
    });

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      discount: 0,
      amount_received: getOverallTotal(updated_items),
    }));
  };

  const handleMinusQty = (product: any): void => {
    const updated_items = data.items.map((item: any) => {
      if (item.id === product.id) {
        item = { ...item, qty: --item.qty };
        return item;
      }
      return item;
    });

    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
      discount: 0,
      amount_received: getOverallTotal(updated_items),
    }));
  };

  const removeProduct = (product_id: string): void => {
    const updated_items = data.items.filter((d: any) => d.id !== product_id);
    setData((prev) => ({
      ...prev,
      items: updated_items,
      total: getOverallTotal(updated_items),
    }));
  };

  const getOverallTotal = (items: any): number => {
    return items.reduce(
      (total: any, item: any) => total + item.qty * item.price,
      0
    );
  };

  const handleChangeDiscount = (discount: string): void => {
    if (!Helper.validateDecimalInput(discount)) {
      return;
    }
    const discountedValue =
      (Number(discount) / 100) * getOverallTotal(data.items);
    const result = getOverallTotal(data.items) - discountedValue;
    const discountedTotal = Helper.formatToTwoDecimal(result);

    setData((prev) => ({
      ...prev,
      discount: parseFloat(discount),
      amount_received: discountedTotal,
      total: discountedTotal,
    }));
  };

  const handleChangeTotal = (total: string): void => {
    if (!Helper.validateDecimalInput(total)) {
      return;
    }

    let discount = Helper.calculateDiscount(
      getOverallTotal(data.items),
      parseFloat(total)
    );

    setData((prev) => ({
      ...prev,
      discount: discount,
      total: parseFloat(total),
      amount_received: parseFloat(total),
    }));
  };

  const handleChangeAmountReceived = (amount_received: string): void => {
    if (!Helper.validateDecimalInput(amount_received)) {
      return;
    }
    setData((prev) => ({
      ...prev,
      amount_received: parseFloat(amount_received),
    }));
  };

  const onChangePaymentType = (event: PaymentType): void => {
    const payment_method = event.value;
    setData((prev) => ({
      ...prev,
      payment_method,
    }));
  };

  const handleChangeRemarks = (remarks: string): void => {
    setData((prev) => ({
      ...prev,
      remarks,
    }));
  };

  const hasChange = (): boolean => {
    return +data.amount_received > +data.total;
  };

  const handleSubmit = async (): Promise<void> => {
    setFormSubmitted(true);

    if (!isValidForm()) {
      return;
    }

    setShowConfirmDeleteModal(true);
  };

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev) => ({
      ...prev,
      date: event.target.value,
    }));
  };

  const revertToOriginalTotal = (): void => {
    setData((prev) => ({
      ...prev,
      total: getOverallTotal(data.items),
      amount_received: getOverallTotal(data.items),
      discount: 0,
    }));
  };

  const confirmSubmitOrder = async (confirm: boolean): Promise<void> => {
    setShowConfirmDeleteModal(false);

    if (confirm) {
      try {
        setLoadingSubmitForm(true);
        await createOrderProductAPI(data);
        closeProductForm(true);
        fetchProductList();
        showToastSuccess("Success", "Order added successfully.");
      } catch (e) {
        showToastDanger("Error", "There was a problem creating the order.");
      } finally {
        setLoadingSubmitForm(false);
      }
    }
  };

  const isValidForm = (): boolean => {
    if (!data.amount_received || !data.total) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Modal size="lg" isOpen={true} toggle={() => closeProductForm(false)}>
        <ModalHeader toggle={() => closeProductForm(false)}>
          Transaction
        </ModalHeader>
        <ModalBody>
          <Row style={{ minHeight: "300px" }}>
            <Col className="col-md-12 mb-3">
              {/* <Label className="font-weight-bold">Select Product</Label> */}
              {/* <SelectProduct
                onChangeProduct={onChangeProduct}
                selectedOption={""}
              /> */}
            </Col>
            {data.items.length > 0 ? (
              <>
                <Col className="col-md-12 mb-3">
                  <table
                    className="table table-bordered text-center"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr className="table-active">
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                        <th style={{ width: "100px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.items.length > 0 &&
                        data.items.map((item: any, index: number) => {
                          return (
                            <tr key={item.id}>
                              <td className="font-weight-bold">{item.name}</td>
                              <td>{item.price}</td>
                              <td>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <button
                                      onClick={() => handleMinusQty(item)}
                                      className="btn btn-white px-3"
                                    >
                                      <i className="fa fa-minus"></i>
                                    </button>
                                  </div>
                                  <Input
                                    readOnly={true}
                                    style={{ width: "60%", margin: "0 auto" }}
                                    className="text-center font-weight-bold"
                                    value={item.qty}
                                  />
                                  <div className="input-group-append">
                                    <button
                                      onClick={() => handleAddQty(item)}
                                      className="btn btn-white px-3"
                                    >
                                      <i className="fa fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>{item.price * item.qty}</td>
                              <td>
                                <button
                                  onClick={() => removeProduct(item.id)}
                                  className="btn btn-sm btn-white"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr className="table-active">
                        <td></td>
                        <td></td>
                        <td colSpan={3} className="p-3">
                          <div className="d-flex justify-content-end">
                            <div className="d-flex justify-content-end align-items-center mr-3">
                              <span
                                className="mr-2 font-weight-bold"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Discount (%):
                              </span>
                              <Input
                                autoComplete="off"
                                onChange={(e) =>
                                  handleChangeDiscount(e.target.value)
                                }
                                className="text-center font-weight-bold"
                                value={data.discount}
                              />
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <span className="mr-2 font-weight-bold">
                                Total:
                              </span>
                              <Input
                                autoComplete="off"
                                onChange={(e) =>
                                  handleChangeTotal(e.target.value)
                                }
                                className="text-center font-weight-bold"
                                value={data.total}
                                invalid={formSubmitted && !data.total}
                              />
                              <button
                                className="btn btn-white ml-2"
                                onClick={() => revertToOriginalTotal()}
                              >
                                <i className="fa fa-redo"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Col>

                <Col className="col-md-6">
                  <Row className="mb-3 align-items-center">
                    <Label className="col-sm-5 mb-0 font-weight-bold">
                      Amount Received
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        className="font-weight-bold"
                        autoComplete="off"
                        value={data.amount_received}
                        onChange={(e) =>
                          handleChangeAmountReceived(e.target.value)
                        }
                        type="text"
                        invalid={formSubmitted && !data.amount_received}
                      />
                      <FormFeedback>Amount is required.</FormFeedback>
                    </div>
                  </Row>
                  <Row className="mb-3 align-items-center">
                    <Label className="col-sm-5 mb-0 font-weight-bold">
                      Payment Method
                    </Label>
                    <div className="col-sm-7">
                      <SelectPaymentMethod
                        onChangePaymentType={onChangePaymentType}
                        selectedOption={data.payment_method}
                      />
                    </div>
                  </Row>
                  {hasChange() || data.total > data.amount_received ? (
                    <>
                      <Row className="mb-3 align-items-center">
                        <Label className="col-sm-5 mb-0 font-weight-bold">
                          {hasChange() ? "Change" : "Balance"}
                        </Label>
                        <div className="col-sm-7">
                          <Label>
                            {hasChange() ? (
                              Helper.formatToTwoDecimal(
                                data.amount_received - data.total
                              )
                            ) : (
                              <span className="font-weight-bold">
                                {Helper.formatToTwoDecimal(
                                  data.total - data.amount_received
                                )}
                              </span>
                            )}
                          </Label>
                        </div>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col className="col-md-6">
                  <Row className="mb-3 align-items-center">
                    <Label className="col-sm-5 mb-0 font-weight-bold">
                      Date
                    </Label>
                    <div className="col-sm-7">
                      <SelectDatePicker
                        onChangeDate={onChangeDate}
                        selectedDate={data.date}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3 align-items-center">
                    <Label className="col-sm-5 mb-0 font-weight-bold">
                      Order No.
                    </Label>
                    <div className="col-sm-7">
                      <Input readOnly value={data.order_number} type="text" />
                    </div>
                  </Row>
                </Col>

                <Col className="col-md-12">
                  <Input
                    onChange={(e) => handleChangeRemarks(e.target.value)}
                    value={data.remarks}
                    type="textarea"
                    rows={3}
                    maxLength={200}
                    placeholder="Enter remarks."
                  />
                  <small className="text-muted float-right">
                    {data.remarks.length}/200 characters remaining
                  </small>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </ModalBody>
        {data.items.length > 0 ? (
          <>
            <ModalFooter>
              <Button onClick={() => {}} color="white" className="mr-auto">
                Delete
              </Button>
              <Button onClick={() => closeProductForm(false)} color="white">
                Cancel
              </Button>{" "}
              <Button
                disabled={loadingSubmitForm}
                type="submit"
                color="success"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        ) : (
          <></>
        )}
      </Modal>
      <ReusableModal
        title="Submit Order"
        message="Are you sure you want to submit this data. Please confirm to proceed."
        showDialog={showConfirmDeleteModal}
        closeDialog={confirmSubmitOrder}
      />
    </>
  );
};

export default TransactionForm;
