import { getTicketLogsAPI, getTicketsListAPI } from "apis/Inventory/TicketAPI";
import { showToastDanger } from "core/toast.service";
import { TicketLog } from "models/Tickets/ticket-log.model";
import { Ticket } from "models/Tickets/ticket.model";
import React, { createContext, useEffect, useState } from "react";

interface TicketOrderContextData {
  tickets: Ticket[];
  ticketLogs: TicketLog[];
  totalLogRows: number;
  loading: boolean;
  loadingLogs: boolean;
  totalRows: number;
  currentLogPage: number;
  fetchTicketList: () => void;
  fetchTicketLogs: () => void;
  onPaginateLogs: (page: number) => void;
}

interface TicketOrderProviderProps {
  children: React.ReactNode;
}

export const TicketOrderContext = createContext<TicketOrderContextData>({
  tickets: [],
  ticketLogs: [],
  totalLogRows: 0,
  loading: true,
  loadingLogs: true,
  totalRows: 0,
  currentLogPage: 1,
  fetchTicketList: () => {},
  fetchTicketLogs: () => {},
  onPaginateLogs: () => {},
});

export const TicketOrderProvider: React.FC<TicketOrderProviderProps> = ({
  children,
}) => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketLogs, setTicketLogs] = useState<any[]>([]);
  const [currentLogPage, setCurrentLogPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [totalLogRows, setTotalLogRows] = useState(0);

  useEffect(() => {
    fetchTicketList();
  }, []);

  useEffect(() => {
    fetchTicketLogs();
  }, [currentLogPage]);

  const fetchTicketList = async () => {
    setLoading(true);
    const result = await getTicketsListAPI();
    renderResult(result);
  };

  const fetchTicketLogs = async () => {
    setLoadingLogs(true);
    try {
      const result = await getTicketLogsAPI(currentLogPage);
      setTicketLogs(result.data);
      setLoadingLogs(false);

      const page = result.page;
      if (page) {
        setTotalLogRows(page.total);
      }
    } catch (e) {
      console.error(e);
      showToastDanger("Error", "There was a problem loading the ticket logs")
    }
  };

  const onPaginateLogs = (page: number) => {
    setCurrentLogPage(page);
  };

  const renderResult = (result: any) => {
    const data = result.data;
    const page = result.page || result.pages;

    if (data) {
      setTickets(data);
      setLoading(false);
    }

    if (page) {
      setTotalRows(page.total);
    }
  };

  return (
    <TicketOrderContext.Provider
      value={{
        tickets,
        ticketLogs,
        loading,
        loadingLogs,
        totalRows,
        totalLogRows,
        currentLogPage,
        fetchTicketList,
        fetchTicketLogs,
        onPaginateLogs,
      }}
    >
      {children}
    </TicketOrderContext.Provider>
  );
};
