
import { CalendarNote } from "models/Calendar/calendar-note.model";
import axios from "./core/Axios";
import { GlobalVar } from "./core/GlobalVar";

export const getCalendarEventsAPI = async () => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get("https://api.npoint.io/e9669091b5351302f54c");
    return response.data;
}

export const getCalendarNotesDailyAPI = async (date: string) => {
    const response = await axios.get(`https://api.npoint.io/e9669091b5351302f54c?date=${date}`);
    return response.data;
}

export const getCalendarNotesWeeklyAPI = async (from: string, to: string) => {
    const response = await axios.get(`https://api.npoint.io/e9669091b5351302f54c?from=${from}&to=${to}`);
    return response.data;
}

export const getCalendarNotesMonthlyAPI = async (month: number, year: number) => {
    const response = await axios.get(`/event-months?month=${month}&year=${year}`, { headers: GlobalVar.Http });
    return response.data;
}

export const addCalendarEventAPI = async (note: CalendarNote) => {
    const response = await axios.post(`/event-note/`, note, { headers: GlobalVar.Http });
    return response.data;
}

export const updateCalendarEventAPI = async (event_id: string, note: CalendarNote) => {
    const response = await axios.put(`/event-note/${event_id}`, note, { headers: GlobalVar.Http });
    return response.data;
}

export const getEventNoteByIdAPI = async (id: string) => {
    const response = await axios.get(`/calendar-note/${id}`);
    return response.data;
}

export const deleteEventByIdAPI = async (id: string) => {
    const response = await axios.delete(`/event/${id}`, { headers: GlobalVar.Http });
    return response.data;
}