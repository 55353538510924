
import { GlobalVar } from "apis/core/GlobalVar";
import axios from "apis/core/Axios";
import { environment } from "environments/environment.prod";

export const createTicketAPI = async (data: any) => {
    const response = await axios.post("/ticket", data, { headers: GlobalVar.Http });
    return response.data;
}

export const updateTicketAPI = async (ticket_id: string, data: any) => {
    const response = await axios.put(`/ticket/${ticket_id}`, data, { headers: GlobalVar.Http });
    return response.data;
}

export const deleteRentalAPI = async (rental_id: string) => {
    const response = await axios.delete(`/rental/${rental_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getTicketLogsAPI = async (currentPage: number) => {
    // const response = await axios.post("/product", data, { headers: GlobalVar.Http });
    // return response.data;
    const response = await axios.get(`/logs/ticket-purchases?page=${currentPage}`, { headers: GlobalVar.Http });
    return response.data;
}

export const getTicketsListAPI = async (currentPage: number = 1) => {
    const response = await axios.get(`/tickets`, { headers: GlobalVar.Http });
    return response.data;
}
export const getTicketSpecificationsAPI = async () => {
    const response = await axios.get(`https://api.npoint.io/12787a733cce1526c460`);
    return response.data;
}

export const findTicketByIDAPI = async (ticket_id: string) => {
    const response = await axios.get(`/ticket/${ticket_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const deleteTicketByIdAPI = async (ticket_id: string) => {
    const response = await axios.delete(`/ticket/${ticket_id}`, { headers: GlobalVar.Http });
    return response.data;
}

export const createOrderTicketAPI = async (data: any) => {
    const response = await axios.post("/purchase/ticket", data, { headers: GlobalVar.Http });
    return response.data;
}


export const getOrderIdByTicketTypeAPI = async () => {
    const response = await axios.get("https://api.npoint.io/e907410965a527f3103f");
    // const response = await axios.get(`/order/ticket/transaction-number`, { headers: GlobalVar.Http });
    return response.data;
}

export const printTicketAPI = async (data: any) => {
    const response = await axios.post(`${environment.node}/print-ticket`, data);
    return response.data;
}

export const checkPrinterStatus = async () => {
    const response = await axios.post(`${environment.node}/printer-status`);
    return response.data;
}