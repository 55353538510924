import { createUserAPI, updateUserAPI } from "apis/UsersAPI";
import SelectColorAssigned from "components/features/SelectColorAssigned";
import SelectUserType from "components/features/SelectUserType";
import { DEFAULT_USER, UsersContext } from "contexts/UsersContext";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { User } from "models/Users/user.model";
import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

interface Props {
  showPersonnelForm: boolean;
  closePersonnelForm: (status: boolean) => void;
}

const PersonnelForm: React.FC<Props> = ({
  showPersonnelForm,
  closePersonnelForm,
}) => {
  const {
    selectedRow,
    formValues,
    users,

    onSelectRow,
    handleFormChange,
    onChangeUserType,
    onChangeColor,
    fetchUsersList,
  } = useContext(UsersContext);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameExist, setUsernameExist] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const saveUser = async (): Promise<void> => {
    setFormSubmitted(true);

    if (!isValidForm()) {
      return;
    }

    setLoadingSubmit(true);

    try {
      if (selectedRow.id) {
        await updateUserAPI(formValues);
        showToastSuccess("Success", "User updated successfully");
      } else {
        await createUserAPI({ ...formValues, id: uuidv4() });
        showToastSuccess("Success", "User created successfully");
      }

      closePersonnelForm(true);
      fetchUsersList();
    } catch (e) {
      showToastDanger("Error", "There was an error submitting the data.");
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onCloseForm = (): void => {
    setFormSubmitted(false);
    onSelectRow(DEFAULT_USER);
    closePersonnelForm(false);
  };

  const isValidForm = (): boolean => {
    if (usernameExist) {
      return false;
    }
    if (
      formValues.username.trim().length === 0 ||
      formValues.name.trim().length === 0
    ) {
      return false;
    }

    if (!selectedRow.id) {
      if (formValues.password.trim().length === 0) {
        return false;
      }
    }
    return true;
  };

  const onChangeUsername = (e: any) => {
    handleFormChange(e);
    const username = e.target.value;

    let accounts: User[] = [];
    if (selectedRow.id) {
      accounts = users.filter(
        (user) =>
          user.username.toLocaleLowerCase() !==
          selectedRow.username.toLocaleLowerCase()
      );
    } else {
      accounts = users;
    }

    const exist = accounts.some(
      (user) =>
        user.username.toLocaleLowerCase() === username.toLocaleLowerCase()
    );

    console.log(accounts);

    console.log(exist);
    setUsernameExist(exist);
  };

  return (
    <>
      <Modal isOpen={showPersonnelForm} toggle={() => onCloseForm()}>
        <ModalHeader toggle={() => onCloseForm()}>
          {selectedRow && selectedRow.id ? "Edit" : "Add"} User
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">User Type</label>
                <div className="col-sm-9">
                  <SelectUserType
                    onChangeAssigned={onChangeUserType}
                    selectedOption={String(formValues.type).toLocaleLowerCase()}
                  />
                </div>
              </div>
            </div>
          </div>

          <FormGroup className="form-group mb-3">
            <Row>
              <Col className="col-sm-3">
                <Label for="name" className="col-form-label">
                  Name
                </Label>
              </Col>
              <Col className="col-sm-9">
                <Input
                  autoComplete="off"
                  value={formValues.name}
                  type="text"
                  name="name"
                  onChange={handleFormChange}
                  className="form-control"
                  invalid={formSubmitted && !formValues.name}
                />
                <FormFeedback>Name is required.</FormFeedback>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="form-group mb-3">
            <Row>
              <Col className="col-sm-3">
                <Label for="username" className="col-form-label">
                  Username
                </Label>
              </Col>
              <Col className="col-sm-9">
                <Input
                  autoComplete="off"
                  value={formValues.username}
                  type="text"
                  name="username"
                  onChange={(e) => onChangeUsername(e)}
                  className="form-control"
                  invalid={
                    (formSubmitted && !formValues.username) || usernameExist
                  }
                />
                {formSubmitted && !formValues.username ? (
                  <FormFeedback>Username is required.</FormFeedback>
                ) : (
                  <></>
                )}

                {usernameExist ? (
                  <FormFeedback>Username already exist.</FormFeedback>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="form-group mb-3">
            <Row>
              <Col className="col-sm-3">
                <Label for="password" className="col-form-label">
                  Password
                </Label>
              </Col>
              <Col className="col-sm-9">
                <Input
                  autoComplete="off"
                  value={formValues.password}
                  type="password"
                  name="password"
                  onChange={handleFormChange}
                  className="form-control"
                  invalid={
                    formSubmitted && !formValues.password && !selectedRow.id
                  }
                />
                <FormFeedback>Password is required.</FormFeedback>

                {/* {selectedRow && selectedRow.id ? (
                  <small>
                    Leave the password blank if you don't want to edit it.
                  </small>
                ) : (
                  <></>
                )} */}
              </Col>
            </Row>
          </FormGroup>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">Color</label>
            <div className="col-sm-9">
              <SelectColorAssigned
                onChangeColor={onChangeColor}
                selectedOption={formValues.color}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">Notes</label>
            <div className="col-sm-9">
              <textarea
                value={formValues.notes}
                className="form-control"
                rows={2}
                name="notes"
                onChange={handleFormChange}
              ></textarea>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            disabled={loadingSubmit}
            onClick={() => closePersonnelForm(false)}
            color="white"
          >
            Cancel
          </Button>
          <Button
            size="lg"
            disabled={loadingSubmit}
            onClick={() => saveUser()}
            color="success"
          >
            {loadingSubmit ? "Saving" : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PersonnelForm;
