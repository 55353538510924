
import { NOTIFICATION_CONTAINER, Store } from 'react-notifications-component';
export const showToastDanger = (notificationTitle: any, notificationMessage: any, notificationPosition: NOTIFICATION_CONTAINER = 'top-right') => {
    Store.addNotification({
        title: notificationTitle,
        message: notificationMessage,
        type: 'danger',
        insert: "top",
        container: notificationPosition,
        // animationIn: ['animate__animated animate__fadeIn'],
        // animationOut: ['animate__animated animate__fadeOut'],
        dismiss: { duration: 2000, showIcon: false, pauseOnHover: true },
        // dismissable: { click: true },
    });
}

export const showToastSuccess = (notificationTitle: any, notificationMessage: any, notificationPosition: NOTIFICATION_CONTAINER = 'top-right') => {
    Store.addNotification({
        title: notificationTitle,
        message: notificationMessage,
        type: 'success',
        insert: "top",
        container: notificationPosition,
        // animationIn: ['animate__animated animate__fadeIn'],
        // animationOut: ['animate__animated animate__fadeOut'],
        dismiss: { duration: 2000, showIcon: false, pauseOnHover: true },
        // dismissable: { click: true },
    });
}