import {
  addCalendarEventAPI,
  deleteEventByIdAPI,
  getEventNoteByIdAPI,
  updateCalendarEventAPI,
} from "apis/CalendarAPI";
import { CalendarContext } from "contexts/CalendarContext";
import { APP_COLORS } from "core/contants";
import ReusableModal from "core/dialog.service";
import { Helper } from "core/helper";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { CalendarNote } from "models/Calendar/calendar-note.model";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import SelectColorAssigned from "./SelectColorAssigned";
import SelectDatePicker from "./SelectDatePicker";
import SelectTimePicker from "./SelectTimePicker";

const INITIAL_EVENT_DATA = {
  id: "",
  date_start: Helper.getCurrentDate(),
  date_end: Helper.getCurrentDate(),
  time_start: "09:00:00",
  time_end: "12:00:00",
  note: "",
  color: APP_COLORS[0],
};

interface Props {
  closeNoteForm: (status: boolean) => void;
}

const CalendarNoteForm: React.FC<Props> = ({ closeNoteForm }) => {
  const [note, setNote] = useState<CalendarNote>(INITIAL_EVENT_DATA);
  const [loadingForm, setLoadingForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const { rerenderCalendar, selectedNote, selectedDate } =
    useContext(CalendarContext);

  useEffect(() => {
    if (selectedNote) {
      setNote(selectedNote);
      return;
    }

    if (selectedDate) {
      setNote({ ...note, date_start: selectedDate, date_end: selectedDate });
    }
  }, []);

  const onChangeColor = (color: string): void => {
    setNote({ ...note, color });
  };

  const handleSubmit = async (): Promise<void> => {
    setFormSubmitted(true);

    if (!isValidForm()) {
      return;
    }

    try {
      setLoadingForm(true);
      if (selectedNote) {
        await updateCalendarEventAPI(selectedNote.id, note);
        showToastSuccess("Success", "Note updated succesfully");
      } else {
        await addCalendarEventAPI(note);
        showToastSuccess("Success", "Note added successfully");
      }

      closeNoteForm(false);
      rerenderCalendar();
    } catch (e) {
      showToastDanger("Error", "There was a problem submitting the data");
    } finally {
      setLoadingForm(false);
    }
  };

  const deleteNote = async (): Promise<void> => {
    if (selectedNote) {
      try {
        await deleteEventByIdAPI(selectedNote.id);
        closeNoteForm(false);
        rerenderCalendar();
        showToastSuccess("Success", "Note deleted successfully");
      } catch (e) {
        showToastDanger("Error", "There was a problem deleting the note");
      }
    }
  };

  const isValidForm = (): boolean => {
    if (note.note.trim().length === 0) {
      return false;
    }
    return true;
  };

  const closeDialog = async (confirm: boolean): Promise<void> => {
    setShowConfirmDeleteModal(false);
    if (confirm) {
      deleteNote();
    }
  };

  const onChangeStartTime = (event: any): void => {
    if (event) {
      const time = event.target.value;
      if (time) {
        setNote((prev) => ({
          ...prev,
          time_start: `${time}:00`,
        }));
      }
    }
  };

  const onChangeEndTime = (event: any): void => {
    if (event) {
      const time = event.target.value;
      if (time) {
        setNote((prev) => ({
          ...prev,
          time_end: `${time}:00`,
        }));
      }
    }
  };

  const onChangeStartDate = (event: any): void => {
    if (event) {
      const date = event.target.value;
      console.log("date", date);
      if (date) {
        setNote((prev) => ({
          ...prev,
          date_start: date,
        }));
      }
    }
  };

  const onChangeEndDate = (event: any): void => {
    if (event) {
      const date = event.target.value;
      if (date) {
        setNote((prev) => ({
          ...prev,
          date_end: date,
        }));
      }
    }
  };

  return (
    <>
      <Modal isOpen={true} toggle={() => closeNoteForm(false)}>
        <ModalHeader toggle={() => closeNoteForm(false)}>
          {selectedNote && selectedNote.id ? "Edit" : "Add"} Event{" "}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col className="col-md-12">
                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="color" className="col-form-label">
                        Date
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <Row>
                        <Col className="col-sm-6">
                          <SelectDatePicker
                            onChangeDate={onChangeStartDate}
                            selectedDate={note.date_start}
                          />
                        </Col>
                        <Col className="col-sm-6">
                          <SelectDatePicker
                            onChangeDate={onChangeEndDate}
                            selectedDate={note.date_end}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="color" className="col-form-label">
                        Time
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <Row>
                        <Col className="col-sm-6">
                          <SelectTimePicker
                            selectedTime={note.time_start}
                            onChangeTime={onChangeStartTime}
                          />
                        </Col>
                        <Col className="col-sm-6">
                          <SelectTimePicker
                            selectedTime={note.time_end}
                            onChangeTime={onChangeEndTime}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="note" className="col-form-label">
                        Note
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <Input
                        required
                        invalid={formSubmitted && !note.note}
                        maxLength={200}
                        placeholder="Enter note..."
                        type="textarea"
                        name="text"
                        rows={3}
                        value={note.note}
                        onChange={(e) =>
                          setNote({ ...note, note: e.target.value })
                        }
                      />
                      <small className="text-muted float-right">
                        {note.note.length}/200 characters remaining
                      </small>
                      <FormFeedback>Note is required.</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="color" className="col-form-label">
                        Color
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <SelectColorAssigned
                        onChangeColor={onChangeColor}
                        selectedOption={note.color}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          {selectedNote && selectedNote.id ? (
            <Button
              size="lg"
              color="danger"
              onClick={() => setShowConfirmDeleteModal(true)}
              className="mr-auto"
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
          <Button
            size="lg"
            disabled={loadingForm}
            color="white"
            onClick={() => closeNoteForm(false)}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            disabled={loadingForm}
            type="button"
            color="success"
            onClick={handleSubmit}
          >
            {loadingForm ? "Saving Event" : "Save Event"}
          </Button>
        </ModalFooter>
      </Modal>

      <ReusableModal
        title="Delete Event"
        message="Are you sure you want to delete this event? Please confirm to proceed."
        showDialog={showConfirmDeleteModal}
        closeDialog={closeDialog}
      />
    </>
  );
};

export default CalendarNoteForm;
