import TablePaginationItem from "components/features/TablePaginationItem";
import { TicketOrderContext } from "contexts/Purchase/TicketOrderContext";
import { Helper } from "core/helper";
import { TicketLog } from "models/Tickets/ticket-log.model";
import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";
const COUNT_PER_PAGE = 10;

const TicketLogs = () => {
  const {
    ticketLogs,
    loadingLogs,
    totalLogRows,
    currentLogPage,
    onPaginateLogs,
  } = useContext(TicketOrderContext);

  const handlePaginate = (e: any, page: number): void => {
    e.preventDefault();
    onPaginateLogs(page);
  };

  return (
    <>
      <h4 className="card-title text-muted mb-0">Ticket Logs</h4>
      {!loadingLogs ? (
        <>
          {ticketLogs.length > 0 ? (
            <ul className="list-group list-group-flush">
              {ticketLogs.map((log: TicketLog, index: number) => {
                return (
                  <li key={index} className="list-group-item d-flex px-0">
                    <div className="d-flex flex-column">
                      <span>{log.message_logs}</span>
                      <span className="text-muted">
                        {log.date} {log.time}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ minHeight: "250px" }}
            >
              <i className="fa fa-file-invoice fa-3x mb-2"></i>
              <p className="text-center mb-0">
                When ordering tickets, logs will appear here
              </p>
            </div>
          )}

          {totalLogRows > COUNT_PER_PAGE ? (
            <div className="mt-3">
              <TablePaginationItem
                totalRows={totalLogRows}
                currentPage={currentLogPage}
                handleClick={handlePaginate}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Skeleton
          height={40}
          count={10}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        />
      )}
    </>
  );
};

export default TicketLogs;
