import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

interface Props {
  totalRows: number;
  currentPage: number;
  handleClick: (e: React.MouseEvent<HTMLAnchorElement>, page: number) => void;
  displayRange?: number;
}

const TablePaginationItem: React.FC<Props> = ({
  totalRows,
  currentPage,
  handleClick,
}) => {
  const items: JSX.Element[] = [];
  const totalPages = Math.ceil(totalRows / 10);
  const displayRange = 5; // Change this to the number of page links to display

  let startPage = Math.max(1, currentPage - Math.floor(displayRange / 2));
  let endPage = Math.min(totalPages, startPage + displayRange - 1);

  if (endPage - startPage < displayRange - 1) {
    startPage = Math.max(1, endPage - displayRange + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    items.push(
      <PaginationItem key={i} active={currentPage === i}>
        <PaginationLink href="#" onClick={(e) => handleClick(e, i)}>
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <>
      <Pagination>
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink
            previous
            href="#"
            onClick={(e) => handleClick(e, currentPage - 1)}
          />
        </PaginationItem>
        {items}
        <PaginationItem disabled={currentPage >= totalRows}>
          <PaginationLink
            next
            href="#"
            onClick={(e) => handleClick(e, currentPage + 1)}
          />
        </PaginationItem>
      </Pagination>
    </>
  );
};

export default TablePaginationItem;
