import { environment } from "../../environments/environment.prod";

export class GlobalVar {
    static HttpFormData: any;
    static Http: any;

    static setHeader(jwt_token: string) {
        const _bearer = jwt_token;
        GlobalVar.HttpHeaders(_bearer);
        GlobalVar.HttpHeaderFormData(_bearer);
    }

    static HttpHeaders(jwt_token: string) {
        const header = {
            'Access-Control-Allow-Origin': `${environment.api}`,
            'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'withCredentials': 'true',
            'Authorization': `Bearer ${jwt_token}`
        };
        return this.Http = header
    }

    static HttpHeaderFormData(jwt_token: string) {
        const header = {
            'Cache-Control': 'no-cache',
            'contentType': 'false',
            'processData': 'false',
            'enctype': 'multipart/form-data',
            'Authorization': `Bearer ${jwt_token}`
        };

        return this.HttpFormData = header;
    }
}