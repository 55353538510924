export const APP_COLORS = [
    "#ffbe76",
    "#ff7979",
    "#badc58",
    "#f9ca24",
    "#f0932b",
    "#eb4d4b",
    "#6ab04c",
    "#30336b",
    "#22a6b3",
    "#be2edd",
    "#4834d4",
    "#264653",
    "#2a9d8f",
    "#e9c46a",
    "#f4a261",
    "#e76f51",
    "#8ecae6",
    "#219ebc",
    "#023047",
    "#ffb703",
    "#fb8500",
    "#CBEF43",
    "#8DE969",
    "#8A84E2"
]

export enum INVENTORY_TYPE {
    PRODUCT = "product",
    SERVICE = "service",
    TICKET = "ticket",
    RENTAL = "rental"
}

export enum CalendarViewType {
    timeGridDay = "timeGridDay",
    timeGridWeek = "timeGridWeek",
    dayGridMonth = "dayGridMonth"
}