import { InventoryProvider } from "contexts/InventoryContext";
import React from "react";
import InventoryContainer from "./components/Inventory/InventoryContainer";

const Inventory = () => {

  return (
    <InventoryProvider>
      <h3>Tickets</h3>
      <InventoryContainer></InventoryContainer>
    </InventoryProvider>
  );
};

export default Inventory;
