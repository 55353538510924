const Menu = [
  { 
  	path: '/events-and-orders', icon: 'fa fa-cash-register', title: 'Purchase / Orders'
  },
  // { 
  // 	path: '/calendar', icon: 'fa fa-calendar-day', title: 'Calendar'
  // },
  { 
  	path: '/tickets', icon: 'fa fa-receipt', title: 'Inventory'
  },
  // { 
  // 	path: '/sales-report', icon: 'fa fa-chart-pie', title: 'Sales Report'
  // },
  // { 
  // 	path: '/analytics', icon: 'fa fa-newspaper', title: 'Analytics'
  // },
  // { 
  // 	path: '/activity-logs', icon: 'fa fa-user-clock', title: 'Activity Logs'
  // },
  { 
  	path: '/users', icon: 'fa fa-user-lock', title: 'Users'
  },
  // { 
  // 	path: '/', icon: 'fa fa-th', title: 'Home'
  // },
  // { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
  //   children: [
  //     { path: '/menu/menu-1-1', title: 'Menu 1.1',
  //       children: [
  //         { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
  //           children: [
  //             { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
  //             { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
  //           ]
  //         },
  //         { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
  //         { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
  //       ]
  //     },
  //     { path: '/menu/menu-1-2', title: 'Menu 1.2' },
  //     { path: '/menu/menu-1-3', title: 'Menu 1.3' },
  //   ]
  // }
]

export default Menu;
