import { PaymentType } from "models/payment-type.model";
import React from "react";
import Select from "react-select";

interface Props {
  onChangePaymentType: (event: any) => void;
  selectedOption: string;
}

const options: PaymentType[] = [
  { value: "cash", label: "Cash" },
  { value: "online", label: "Online" },
];

const SelectPaymentMethod: React.FC<Props> = ({
  onChangePaymentType,
  selectedOption,
}: Props) => {
  return (
    <Select
      defaultValue={options[0]}
      isClearable={false}
      isSearchable={false}
      onChange={onChangePaymentType}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={options}
    />
  );
};

export default SelectPaymentMethod;
