import { createTicketAPI, updateTicketAPI } from "apis/Inventory/TicketAPI";
import SelectAssignedRole from "components/features/SelectAssignedRole";
import SelectColorAssigned from "components/features/SelectColorAssigned";
import SelectSpecification from "components/features/SelectSpecification";
import { InventoryContext } from "contexts/InventoryContext";
import { APP_COLORS, INVENTORY_TYPE } from "core/contants";
import { Helper } from "core/helper";
import { showToastDanger, showToastSuccess } from "core/toast.service";
import { TicketFormInventory } from "models/Inventory/ticket-form-inventory";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

interface Props {
  closeInventoryForm: (status: boolean) => void;
}

const InventoryForm: React.FC<Props> = ({ closeInventoryForm }) => {
  const { selectedTab, selectedRow, fetchTicketList, onSelectRow } =
    useContext(InventoryContext);

  const initialFormData: TicketFormInventory = {
    assign_to_roles: ["Guard"],
    capital: 1,
    color: APP_COLORS[0],
    date: Helper.getCurrentDate(),
    description: "",
    name: "",
    price: 0,
    qty: 1,
    specification: "adult",
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] =
    useState<TicketFormInventory>(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (selectedRow) {
      setFormData((prev) => ({
        ...selectedRow,
        assign_to_roles: selectedRow.assign_roles,
      }));
    }

    return () => {
      onSelectRow(null);
    };
  }, []);

  const handleChangeInput = (event: any): void => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const onChangeAssigned = (selected: any): void => {
    const roles = selected.map((select: any) => select.label);
    setFormData((prevFormData) => ({
      ...prevFormData,
      assign_to_roles: roles,
    }));
  };

  const onChangeSpecification = (event: any): void => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      specification: event.value,
    }));
  };

  const onChangeColor = (color: string): void => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      color: color,
    }));
  };

  const handleSubmitForm = async (event: any): Promise<void> => {
    event.preventDefault();

    setFormSubmitted(true);

    if (!isFormValid()) {
      return;
    }

    try {
      setLoadingForm(true);
      if (selectedRow && selectedRow.id) {
        await updateTicketAPI(selectedRow.id, formData);
        showToastSuccess("Success", "Record updated successfully");
      } else {
        await createTicketAPI(formData);
        showToastSuccess("Success", "Record added successfully");
      }

      fetchTicketList();
      closeInventoryForm(true);
    } catch (e) {
      showToastDanger("Error", "There was an error submitting the data");
    } finally {
      setLoadingForm(false);
    }
  };

  const handleChangePrice = (price: string): void => {
    if (!Helper.validateDecimalInput(price)) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      price: price ? parseFloat(price) : "",
    }));
  };

  const isFormValid = (): boolean => {
    if (!formData.price || !formData.name) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Modal isOpen={true} toggle={() => closeInventoryForm(false)}>
        <ModalHeader toggle={() => closeInventoryForm(false)}>
          {selectedRow && selectedRow.id ? "Edit" : "Add"} Ticket
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Specification
                  </label>
                  <div className="col-sm-9">
                    <SelectSpecification
                      onChangeSpecification={onChangeSpecification}
                      selectedOption={formData.specification}
                    />
                  </div>
                </div>
                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="username" className="col-form-label">
                        Name
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <Input
                        placeholder="Eg. Entrance, Pool"
                        autoComplete="off"
                        name="name"
                        value={formData.name}
                        onChange={handleChangeInput}
                        type="text"
                        className="form-control"
                        invalid={formSubmitted && !formData.name}
                      />
                      <FormFeedback>Name is required.</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="description" className="col-form-label">
                        Description
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <Input
                        type="textarea"
                        name="description"
                        value={formData.description}
                        onChange={handleChangeInput}
                        className="form-control"
                        maxLength={200}
                        rows={2}
                      />
                      <small className="text-muted float-right">
                        {formData.description.length}/200 characters remaining
                      </small>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="form-group mb-3">
                  <Row>
                    <Col className="col-sm-3">
                      <Label for="price" className="col-form-label">
                        Price
                      </Label>
                    </Col>
                    <Col className="col-sm-9">
                      <Input
                        autoComplete="off"
                        name="price"
                        value={formData.price}
                        onChange={(e) => handleChangePrice(e.target.value)}
                        type="number"
                        className="form-control"
                        invalid={formSubmitted && !formData.price}
                      />
                      <FormFeedback>Price is required.</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Color</label>
                  <div className="col-sm-9">
                    <SelectColorAssigned
                      onChangeColor={onChangeColor}
                      selectedOption={formData.color}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Assigned To</label>
                  <div className="col-sm-9">
                    <SelectAssignedRole
                      onChangeAssigned={onChangeAssigned}
                      selectedOption={formData.assign_to_roles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            disabled={loadingForm}
            onClick={() => closeInventoryForm(false)}
            color="white"
          >
            Cancel
          </Button>
          <Button
            size="lg"
            disabled={loadingForm}
            type="submit"
            color="success"
            onClick={handleSubmitForm}
          >
            {loadingForm ? "Saving Ticket" : "Save Ticket"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InventoryForm;
