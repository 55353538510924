import React, { useEffect, useState } from "react";
import Select from "react-select";

interface Props {
  onChangeSpecification: (event: any) => void;
  selectedOption: string;
}

interface SelectSpecification {
  label: string;
  value: string;
}

const OPTIONS: SelectSpecification[] = [
  { label: "Adult", value: "adult" },
  { label: "Children", value: "children" },
  { label: "PWD", value: "pwd" },
  { label: "VIP", value: "vip" },
];

const SelectSpecification: React.FC<Props> = ({
  onChangeSpecification,
  selectedOption,
}: Props) => {
  const [options, setOptions] = useState<SelectSpecification[]>(OPTIONS);
  const [defaultOption, setDefaultOption] = useState(OPTIONS[0]);

  useEffect(() => {
    if (selectedOption) {
      const result = options.find(
        (option: any) => option.value === selectedOption
      );

      if (result) {
        setDefaultOption(result);
      }
    }
  }, [selectedOption]);

  return (
    <Select
      value={defaultOption}
      onChange={onChangeSpecification}
      isClearable={false}
      isSearchable={false}
      options={options}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default SelectSpecification;
